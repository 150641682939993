import { bbox } from '@turf/bbox';
import {
  borderColor, minValueColor, maxValueColor,
  zoomThreshold,
} from './utils';

const sourceName = 'regions-data';
const fillLayerName = 'regions-fill';
const outlineLayerName = 'regions-outline';

export default class RegionsLayer {
  constructor(map, data) {
    this.map = map;
    this.data = data;

    this.currentView = 'publicMarkets';

    // Pour savoir si et sur quelle région on a mis un popup après le passage du user
    this.popupedRegion = null;
    // Pour savoir sur quelle région on doit zoomer
    this.regionFocusedOn = null;

    this.map.addSource(sourceName, {
      type: 'geojson',
      data: this.data,
    });

    this.fillLayerName = fillLayerName;
    this.outlineLayerName = outlineLayerName;
  }

  add() {
    this.addFillLayer();
    this.addOutlineLayer();
  }

  changeColoredPropertyTo(coloredProperty) {
    const expression = this.buildFillColorExpressionFor(coloredProperty);

    this.map.setPaintProperty(
      fillLayerName,
      'fill-color',
      expression,
    );
  }

  addFillLayer() {
    this.map.addLayer({
      id: fillLayerName,
      type: 'fill',
      source: sourceName,
      maxzoom: zoomThreshold,
      paint: {
        'fill-color': this.buildFillColorExpressionFor('totalCount'),
        'fill-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          6, 1,
          8, 0,
        ],
      },
    });
  }

  buildFillColorExpressionFor(coloredProperty) {
    let propertyName;
    let expression;
    let maxValue;

    if (coloredProperty === 'totalCount') {
      propertyName = this.currentView === 'publicMarkets' ? 'publicMarketsCount' : 'providersCount';
      maxValue = this.currentView === 'publicMarkets' ? this.publicMarketsCountMaxValue : this.providersCountMaxValue;
    } else {
      propertyName = this.currentView === 'publicMarkets' ? 'publicMarketsTotalAmount' : 'providersTotalAmount';
      maxValue = this.currentView === 'publicMarkets' ? this.publicMarketsAmountMaxValue : this.providersAmountMaxValue;
    }

    // Il y a un bug si minValue === maxValue
    // Parce que dans setPaintProperty, les limites
    // doivent être strictement dans un ordre ascendant
    if (maxValue === 0) {
      expression = minValueColor;
    } else {
      expression = ['interpolate',
        ['exponential', 0.9],
        ['get', propertyName], // Utilise la valeur fusionnée
        0, minValueColor,
        maxValue, maxValueColor,
      ];
    }

    return expression;
  }

  addOutlineLayer() {
    this.map.addLayer({
      id: outlineLayerName,
      type: 'line',
      source: sourceName,
      paint: {
        'line-color': borderColor,
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          0, [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            3,
            1,
          ],
          zoomThreshold, [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            3,
            1,
          ],
          zoomThreshold + 0.1, 3,
        ],
      },
    });
  }

  on(eventName, callback) {
    this.map.on(eventName, fillLayerName, (event) => {
      callback(event);
    });
  }

  get providersCountMaxValue() {
    return Math.max(...this.data.features.map((f) => f.properties.providersCount));
  }

  get publicMarketsCountMaxValue() {
    return Math.max(...this.data.features.map((f) => f.properties.publicMarketsCount));
  }

  get providersAmountMaxValue() {
    return Math.max(...this.data.features.map((f) => f.properties.providersTotalAmount));
  }

  get publicMarketsAmountMaxValue() {
    return Math.max(...this.data.features.map((f) => f.properties.publicMarketsTotalAmount));
  }

  get bbox() {
    return bbox(this.data);
  }

  set initialZoomLevel(value) {
    // this.map.setLayerZoomRange(fillLayerName, 0, value + 2);
    // this.map.setLayerZoomRange(outlineLayerName, 0, value + 2);
    this.map.setPaintProperty(fillLayerName, 'fill-opacity', [
      'interpolate',
      ['linear'],
      ['zoom'],
      value, 1,
      value + 3, 0,
    ]);

  //   'fill-opacity': [
  //     'interpolate',
  //     ['linear'],
  //     ['zoom'],
  //     4, 0, // Zoom 4 : totalement transparent
  //     5, 1  // Zoom 5 : totalement opaque
  // ]
  }
}
