import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="map"
export default class extends Controller {
  static targets = ['map'];

  connect() {

  }
}
