import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [];

  connect() {
    this.tabs = [...this.element.querySelectorAll('[data-tab]')];
    this.navTabs = [...this.element.querySelectorAll('[data-tab-target')];

    // Hide all tabs but first by default
    this.hideAll();
    const firstTab = this.tabs[0];
    const firstTabName = firstTab.dataset.tab;
    this.show(firstTabName);

    // const navTabToShow = this.navTabs[0];
    // navTabToShow.classList.add('active');
    // const targetToShow = navTabToShow.dataset.tabTarget;
    // this.show(targetToShow);
  }

  activate(e) {
    e.preventDefault();
    const navTab = e.currentTarget;
    const { tabTarget } = navTab.dataset;
    this.hideAll();
    this.show(tabTarget);
  }

  hideAll() {
    this.tabs.forEach((tab) => {
      tab.style.display = 'none';
      const tabName = tab.dataset.tab;
      this.navTabs.find((navTab) => navTab.dataset.tabTarget === tabName).classList.remove('active');
    });
  }

  show(targetName) {
    this.tabs.find((tab) => tab.dataset.tab === targetName).style.display = 'flex';
    this.navTabs.find((navTab) => navTab.dataset.tabTarget === targetName).classList.add('active');
  }
}
