/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['counter', 'checkbox', 'toggleAllCheckbox', 'operationsWraper', 'selectedInput', 'parentCheckbox', 'parentCounter'];

  static values = {
    modelName: String,
    insideAccordion: Boolean,
    storage: {
      type: Boolean,
      default: true,
    },
  };

  initialize() {
    this.storageName = `bulk_${this.modelNameValue}_selection`;
  }

  connect() {
    if (this.shouldClearSelection()) {
      this.clear();
    } else {
      this.selected = JSON.parse(sessionStorage.getItem(this.storageName)) || [];

      this.checkSelected();
      this.updateCounter();
      this.updateSelectedInput();
      this.toggleOperations();
      this.updateToggleAllCheckbox();
    }

    this.connectedCheckboxCheckedEventListener();

    document.documentElement.addEventListener('turbo:before-stream-render', (event) => {
      const { target } = event.detail.newStream;
      const domTarget = document.getElementById(target);
      if (this.element.contains(domTarget)) {
        this.connectedCheckboxCheckedEventListener();
      }
    });
  }

  connectedCheckboxCheckedEventListener() {
    document.addEventListener('bulk-select:checkbox:checked', (event) => {
      this.updateToggleAllCheckbox();
      if (this.hasInsideAccordionValue && this.insideAccordionValue) {
        const parent = event.detail.checkbox.closest('[data-bulk-select-checkboxes-parent]');
        if (!parent) return;
        this.updateParentCheckbox(parent);
        this.updateParentCounter(parent);
      }
    });
  }

  toggleAll(event) {
    event.preventDefault();
    const checkboxes = [...this.element.querySelectorAll('[data-bulk-select-target="checkbox"]')];
    const { checked } = event.target;

    checkboxes.forEach((checkbox) => {
      checkbox.checked = checked;
      this.toggle({ target: checkbox });
    });
  }

  // When redirect the user after its bulk update ended,
  // we set the hash to #clear to indicate that we should reset
  // the sessionStorage
  // If the hash is present => clear the selection
  // (We can not use a query params because it would remain when clicking on pagination)
  shouldClearSelection() {
    return window.location.hash && window.location.hash === '#clear_selection';
  }

  toggle(e) {
    const { target } = e;
    const modelId = target.value;

    if (target.checked) {
      if (!this.selected.includes(modelId)) {
        this.selected.push(modelId);
      }
    } else if (this.selected.includes(modelId)) {
      this.selected = this.selected.filter((id) => id !== modelId);
    }

    this.updateStorage();
    this.updateSelectedInput();
    this.updateCounter();
    this.toggleOperations();

    const parent = target.closest('[data-bulk-select-checkboxes-parent]');
    if (parent) {
      this.updateParentCounter(parent);
      this.updateParentCheckbox(parent);
    }

    if (this.hasToggleAllCheckboxTarget) {
      this.updateToggleAllCheckbox();
    }
  }

  updateToggleAllCheckbox() {
    this.toggleAllCheckboxTarget.checked = this.selected.length && this.checkboxTargets.filter((c) => c.checked).length;
  }

  toggleAllChildren(e) {
    const { target } = e;
    const { checked } = target;
    const parent = target.closest('[data-bulk-select-checkboxes-parent]');
    const checkboxes = [...parent.querySelectorAll('[data-bulk-select-target="checkbox"]')];

    checkboxes.forEach((checkbox) => {
      checkbox.checked = checked;
      this.toggle({ target: checkbox });
    });
  }

  checkSelected() {
    this.checkboxTargets.forEach((checkbox) => {
      if (this.selected.includes(checkbox.value)) {
        checkbox.checked = true;
        if (this.hasInsideAccordionValue && this.insideAccordionValue) {
          const parent = checkbox.closest('[data-bulk-select-checkboxes-parent]');
          if (!parent) return;
          const parentCheckbox = parent.querySelector('[data-bulk-select-target="parentCheckbox"]');
          parentCheckbox.checked = true;
          this.updateParentCounter(parent);
        }
      } else if (checkbox.checked) {
        checkbox.checked = false;
      }
    });
  }

  checkboxTargetDisconnected(checkbox) {
    this.toggle({ target: checkbox });
  }

  updateParentCounter(parent) {
    const counter = parent.querySelector('[data-bulk-select-target="parentCounter"]');
    if (counter) {
      const childrenSelectedCount = parent.querySelectorAll('[data-bulk-select-target="checkbox"]:checked').length;
      counter.innerText = childrenSelectedCount;
    }
  }

  updateParentCheckbox(parent) {
    const checkbox = parent.querySelector('[data-bulk-select-target="parentCheckbox"]');
    if (checkbox) {
      const childrenSelectedCount = parent.querySelectorAll('[data-bulk-select-target="checkbox"]:checked').length;
      checkbox.checked = childrenSelectedCount > 0;
    }
  }

  toggleOperations() {
    if (this.selected.length) {
      this.operationsWraperTarget.style.display = 'flex';
    } else {
      this.operationsWraperTarget.style.display = 'none';
    }
  }

  updateCounter() {
    this.counterTarget.innerText = this.selected.length;
    this.toggleOperations();
  }

  clear(e) {
    if (e) e.preventDefault();

    this.selected = [];
    this.checkSelected();
    this.updateStorage();
    this.updateCounter();

    if (this.hasInsideAccordionValue && this.insideAccordionValue) {
      this.parentCheckboxTargets.forEach((parentCheckbox) => {
        parentCheckbox.checked = false;
      });
      this.parentCounterTargets.forEach((parentCounter) => {
        parentCounter.innerText = '0';
      });
    }

    if (this.hasToggleAllCheckboxTarget) {
      this.toggleAllCheckboxTarget.checked = false;
    }
  }

  updateSelectedInput() {
    console.log('selected input targets', this.selectedInputTargets);
    this.selectedInputTargets.forEach((input) => {
      input.value = this.selected.join(',');
    });
  }

  updateStorage() {
    if (this.storageValue) {
      sessionStorage.setItem(this.storageName, JSON.stringify(this.selected));
    }
  }
}
