import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
  }

  submit(event) {
    this.element.requestSubmit();
  }
}
