import tippy from 'tippy.js';
import { dateFromLocaleString, daysBetween } from '../../utils';
import { addDaysToDate, movementDirection, toggleCurrentDateMarker } from './step_drag_resize_utils';

import CollisionHandler from './collision_handler';

export default class StepResizer {
  constructor(element) {
    this.element = element;
    this.wasResized = false;
    this.scrollWraper = document.getElementById('roadmap_scroller');

    this.durationMin = parseFloat(this.element.dataset['planner-RoadmapStepDurationMinValue']) + 1;
    this.durationMax = parseFloat(this.element.dataset['planner-RoadmapStepDurationMaxValue']) + 1;


    this.minWidth = (document.querySelector('.roadmap__date').offsetWidth * this.durationMin) - 6;
    this.maxWidth = (document.querySelector('.roadmap__date').offsetWidth * this.durationMax) - 6;

    // this.element.style.minWidth = `${this.minWidth}px`;
    this.setupListeners();
  }

  setupListeners() {
    this.resizeStartHandler = this.resizeStart.bind(this);
    this.resizeHandler = this.resize.bind(this);
    this.resizeStopHandler = this.resizeStop.bind(this);

    const grabers = [this.grabLeft, this.grabRight];
    grabers.forEach((grabElement, index) => {
      grabElement.addEventListener('mousedown', (event) => this.resizeStartHandler(event, grabers[index]));
    });
  }

  resizeStart(event, graber) {
    event.stopPropagation();
    this.emitEvent('resize-start');

    // Ne pas movesi on est déjà en train de resize
    if (this.element.classList.contains('roadmap-step--resizing')) return;

    this.collisionHandler = new CollisionHandler(this.element);

    this.screenX = event.screenX;
    this.previousMouseScreenX = event.screenX;

    this.initialWidth = this.element.offsetWidth;
    this.initialElementStartX = parseFloat(this.element.dataset.x);

    this.graber = graber;
    this.graber.classList.toggle('active');

    this.tipy = tippy(this.element, {
      placement: 'top-start',
      arrow: false,
      allowHTML: true,
      trigger: 'manual',
    });

    this.initialStartOn = dateFromLocaleString(this.element.dataset['planner-RoadmapStepStartOnValue']);
    this.initialEndOn = dateFromLocaleString(this.element.dataset['planner-RoadmapStepEndOnValue']);

    this.element.classList.toggle('roadmap-step--resizing');

    document.addEventListener('mousemove', this.resizeHandler);
    document.addEventListener('mouseup', this.resizeStopHandler);
  }

  resize(event) {
    if (event.buttons === 1) {
      event.preventDefault();
      this.tipy.show();

      this.movementDelta = event.screenX - this.previousMouseScreenX;
      this.direction = this.movementDelta >= 0 ? 'right' : 'left';

      if (this.graber === this.grabLeft && !(this.direction === 'right' && this.initialWidth <= this.minWidth) && !(this.direction === 'left' && this.initialWidth >= this.maxWidth)) {
        this.handleLeftResize();
      } else if (this.graber === this.grabRight && !(this.direction === 'left' && this.initialWidth <= this.minWidth) && !(this.direction === 'right' && this.initialWidth >= this.maxWidth)) {
        this.handleRightResize();
      } else {
        this.limitExceeded();
        return
      }

      this.setDatesData();
      this.updateTipyContent();

      this.collisionHandler.checkAndHandleCollision(
        this.graber === this.grabLeft && this.direction === 'left', // Observer le côté gauche si graber = le bord gauche et qu'on se déplace vers la gauche
        this.graber === this.grabRight && this.direction === 'right',
      );

      this.previousMouseScreenX = event.screenX;
    }
  }

  handleLeftResize() {
    this.element.classList.remove('roadmap-step--limit-exceeded');
    // Calcul de la nouvelle position de départ
    this.newStartPosition = this.initialElementStartX + this.movementDelta;

    this.initialElementStartX = this.newStartPosition;

    // Longueur minimum = 1 jour
    const newWidth = this.direction === 'left'
      ? this.initialWidth + Math.abs(this.movementDelta)
      : this.initialWidth - Math.abs(this.movementDelta);

    let finalWidth;
    const styles = {};

    // Trouve et active le marker de début
    this.startDateMarker = this.activateStartDateMarker();

    // console.log('new width', newWidth, 'min width', this.minWidth);
    // Si la longueur minimale autorisée n'est pas atteinte
    if (newWidth > this.minWidth) {
      finalWidth = newWidth;
      // console.log('new width > minWidth, on est dans el cas normal on redimensionne');
      // Applique les styles
      styles.transform = `translateX(${this.newStartPosition}px)`;
      styles.width = `${finalWidth}px`;
    } else {
      // console.log('new width <= minWidth, on ne bouge plus');

      finalWidth = this.minWidth;
      styles.width = `${finalWidth}px`;
    }

    Object.assign(this.element.style, styles);

    // Indique ela nouvelle dimension de this.element pour le prochain
    // evenement dragMove
    this.initialWidth = finalWidth;
    this.wasResized = true;
  }

  handleRightResize() {
    this.element.classList.remove('roadmap-step--limit-exceeded');

    const newWidth = this.direction === 'left'
      ? this.initialWidth - Math.abs(this.movementDelta)
      : this.initialWidth + Math.abs(this.movementDelta);

    // Applique les styles
    Object.assign(this.element.style, {
      width: `${newWidth}px`,
    });

    // Trouve et active le marker de début
    this.endDateMarker = this.activateEndDateMarker();

    this.initialWidth = newWidth;
    this.wasResized = true;
  }

  setDatesData() {
    if (this.graber === this.grabLeft) {
      this.startOn = dateFromLocaleString(this.startDateMarker.dataset.date);
      this.duration = daysBetween(this.startOn, this.initialEndOn);
      this.endOn = this.initialEndOn;
    } else {
      this.startOn = this.initialStartOn;
      this.endOn = dateFromLocaleString(this.endDateMarker.dataset.date);
      this.duration = daysBetween(this.startOn, this.endOn);
    }
  }

  updateTipyContent() {
    this.tipy.setContent(this.tipyMsg);
    if (this.graber === this.grabRight) {
      this.tipy.setProps({ placement: 'top-end' });
    }
  }

  activateStartDateMarker() {
    // On identifie le dateMarker sur lequel le bord gauche de this.element se trouve
    // en créant un petit rectanble pour reproduire le bord gauche de this.element
    const elementLeftEdgeAfterMove = {
      left: this.newStartPosition + 1,
      right: this.newStartPosition + 3,
    };
    return toggleCurrentDateMarker(
      elementLeftEdgeAfterMove,
      true,
    );
  }

  activateEndDateMarker() {
    // On identifie le dateMarker sur lequel le bord droit de this.element se trouve
    // en créant un petit rectanble pour reproduire le bord droit de this.element
    const elementRightEdgeAfterMove = {
      left: this.initialElementStartX + this.initialWidth + 1,
      right: this.initialElementStartX + this.initialWidth + 2,
    };
    return toggleCurrentDateMarker(
      elementRightEdgeAfterMove,
      false,
    );
  }

  resizeStop() {
    if (this.wasResized) {
      if (this.graber === this.grabLeft) {
        // Il faut maintenant placer définitivement l'élément
        // sur le bord de startDateMarker
        const finalPosition = parseFloat(this.startDateMarker.dataset.screenX);
        const diffFromNewStartPosition = Math.abs(finalPosition - this.newStartPosition);
        const finalWidth = this.direction === 'left'
          ? this.initialWidth + diffFromNewStartPosition
          : this.initialWidth + diffFromNewStartPosition;

        Object.assign(this.element.style, {
          transform: `translateX(${finalPosition}px)`,
          width: `${finalWidth}px`,
        });
        // Ne pas update data-x si c'est le bord droit qui bouge
        Object.assign(this.element.dataset, {
          x: finalPosition,
        });
      } else {
        // Il faut maintenant étendre l'élément définitvement
        // jusqu'au bord droit de this.endDateMarker
        const endDateMarkerStartX = parseFloat(this.endDateMarker.dataset.screenX);
        const endDateMarkerEndX = endDateMarkerStartX + this.endDateMarker.offsetWidth;
        const finalWidth = endDateMarkerEndX - this.initialElementStartX - 4;

        Object.assign(this.element.style, {
          width: `${finalWidth}px`,
        });
      }

      this.emitEvent('resized', {
        startOn: this.startOn,
        endOn: this.endOn,
        duration: this.duration,
      });

      Array.from(document.querySelectorAll('[data-screen-x]')).find((dateMarkerElement) => dateMarkerElement.classList.contains('active')).classList.remove('active', 'active--left', 'active--right');
    }

    document.removeEventListener('mousemove', this.resizeHandler);
    document.removeEventListener('mouseup', this.resizeStopHandler);

    this.element.classList.toggle('roadmap-step--resizing');
    this.graber.classList.toggle('active');

    

    this.tipy.destroy();
  }

  emitEvent(eventName, details) {
    const event = new CustomEvent(`step-resizer:${eventName}`, { detail: details });
    this.element.dispatchEvent(event);
  }

  limitExceeded() {
    this.element.classList.add('roadmap-step--limit-exceeded');
  }

  get grabLeft() {
    return this.element.querySelector('[data-resize-left="true"]');
  }

  get grabRight() {
    return this.element.querySelector('[data-resize-right="true"]');
  }

  get tipyMsg() {
    return `<small>${this.startOn.toLocaleDateString()} - ${this.endOn.toLocaleDateString()} (${this.duration} jours)</small>`;
  }
}
