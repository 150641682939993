const lineHtml = (line, index) => `
    <div class="timeline__line" id="line_${index}">
    </div>
  `;

const pmHtml = (pm, index) => `
  <a data-turbo-frame="side_panel" href="${pm.link_to}" class="timeline__event"></a>
  `;

const fromHTML = (html) => {
  // Then set up a new template element.
  const template = document.createElement('template');
  template.innerHTML = html;
  const result = template.content.children;

  // Then return either an HTMLElement or HTMLCollection,
  // based on whether the input HTML had one or more roots.
  if (result.length === 1) return result[0];
  return result;
};

export default class Timeline {
  constructor(timelineElement, lines) {
    this.timelineElement = timelineElement;
    this.linesElement = document.getElementById('lines');
    this.lines = lines;

    this.lineHeight = 10;
    this.timelineElement.style.height = `${(this.lines.length + 1) * this.lineHeight + 95}px`;
  }

  displayLines() {
    this.addLinesToDom();
  }

  addLinesToDom() {
    this.lines.forEach((line, lineIndex) => {
      const html = lineHtml(line, lineIndex);
      const lineDiv = fromHTML(html);
      this.linesElement.append(lineDiv);

      line.forEach((pm, index) => {
        const htmlPm = pmHtml(pm, index);
        const pmDiv = fromHTML(htmlPm);

        lineDiv.append(pmDiv);

        const startWeekDiv = document.getElementById(`week_${pm.start_on_year}_${pm.start_on_week}`);
        const endWeekDiv = document.getElementById(`week_${pm.end_on_year}_${pm.end_on_week}`);
        const pmWidth = endWeekDiv.offsetLeft - startWeekDiv.offsetLeft;

        pmDiv.style.left = `${startWeekDiv.offsetLeft}px`;
        pmDiv.style.width = `${pmWidth}px`;
        pmDiv.dataset.followingCursorTooltip = `${pm.tooltip}`;

        lineDiv.classList.remove('d-none');
        lineDiv.style.top = `${lineIndex * this.lineHeight}px`;
      });
    });
  }
}
