import { Controller } from '@hotwired/stimulus';

// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  static targets = ['content', 'chevron'];

  connect() {
    this.activeClass = 'js-active';
    if (this.hasChevronTarget) {
      this.chevronTarget.style.transition = 'transform .1s ease-in';
      this.chevronTarget.style.transformOrigin = '50% 50%';
    }
    // Wether the content element is set by a target or by an ID
    // TargetType can be null, "target" or "id"
    this.targetType = this.element.dataset.targetType || 'target';
    if (this.targetType === 'id') {
      this.contentElement = document.getElementById(this.element.dataset.contentId);
    } else {
      this.contentElement = this.contentTarget;
    }

    if (this.contentElement && this.hasChevronTarget && this.contentElement.classList.contains('js-active')) {
      this.chevronTarget.style.transform = 'rotate(180deg)';
    }
  }

  toggle(e) {
    if (e) e.preventDefault();
    if (this.contentElement.classList.contains(this.activeClass)) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    this.contentElement.classList.add(this.activeClass);
    this.element.classList.add('show-togglable-content');
    this.element.classList.remove('hide-togglable-content');
    if (this.hasChevronTarget) {
      this.chevronTarget.style.transform = 'rotate(180deg)';
    }
  }

  hide() {
    this.contentElement.classList.remove(this.activeClass);
    this.element.classList.add('hide-togglable-content');
    this.element.classList.remove('show-togglable-content');
    if (this.hasChevronTarget) {
      this.chevronTarget.style.transform = 'rotate(0deg)';
    }
  }
}
