import AssistantSelectAllController from './select_all_controller';
import assistantSelectedMandatesStorageKey from './utils';

export default class extends AssistantSelectAllController {
  static targets = ['opsBtn'];

  checkBoxChanged() {
    super.checkBoxChanged();
    this.toggleOpsBtn();
    this.setCounter();
  }

  saveSelection(e) {
    const { onlyNotLocked } = e.params;

    const selectedCheckBoxes = onlyNotLocked
      ? this.checkBoxTargets.filter(
        (checkBox) => checkBox.checked && checkBox.dataset.codeAssignationLocked !== 'true',
      )
      : this.checkBoxTargets.filter(
        (checkBox) => checkBox.checked,
      );

    const selectedMandateIds = selectedCheckBoxes.map((checkBox) => checkBox.dataset.mandateId);

    sessionStorage.setItem(assistantSelectedMandatesStorageKey, JSON.stringify(selectedMandateIds));
  }

  toggleOpsBtn() {
    if (this.someChecked()) {
      this.opsBtnTargets.forEach((target) => target.classList.remove('disabled'));
    } else {
      this.opsBtnTargets.forEach((target) => target.classList.add('disabled'));
    }
  }

  checkAll() {
    super.checkAll();
    this.opsBtnTargets.forEach((target) => target.classList.remove('disabled'));
  }

  uncheckAll() {
    super.uncheckAll();
    this.opsBtnTargets.forEach((target) => target.classList.add('disabled'));
  }
}
