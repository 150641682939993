import { Controller } from '@hotwired/stimulus';

const modalBorderSize = 4;

export default class extends Controller {
  static targets = ['overlay', 'template', 'resizeBtn'];

  connect() {
    this.modal = this.element.querySelector('.side-panel-modal');
    this.modalPosition = null;

    this.initialSize = this.modal.offsetWidth;
    this.actualSize = 'base';

    this.boundResize = this.resize.bind(this);

    // window.scrollTo(0, 0);
    // if (!this.hasTemplateTarget) {
    //   // document.body.style.overflow = 'hidden';
    // }

    this.addResizeEventListeners();
  }

  toggleSize(event) {
    event.preventDefault();
    console.log('actual', this.actu);
    if (this.actualSize === 'base') {
      this.modal.style.width = `${this.initialSize / 2}px`;
      this.actualSize = 'reduced';
      this.resizeBtnTarget.innerHTML = '<i class="bi bi-arrow-bar-left me-1"></i>Agrandir';
    } else {
      this.modal.style.width = `${this.initialSize}px`;
      this.actualSize = 'base';
      this.resizeBtnTarget.innerHTML = '<i class="bi bi-arrow-bar-right me-1"></i>Réduire';
    }
  }

  open(e) {
    e.preventDefault();
    if (this.hasTemplateTarget) {
      // document.body.style.overflow = 'hidden';
      this.templateTarget.classList.add('side-panel--open');
    }
  }

  close(event) {
    if (this.hasTemplateTarget) {
      this.templateTarget.classList.remove('side-panel--open');
    } else {
      this.element.style.display = 'none';
    }
    // document.body.style.overflow = 'auto';
  }

  disconnect() {
    // document.body.style.overflow = 'auto';
  }

  addResizeEventListeners() {
    this.modal.addEventListener('mousedown', (e) => {
      if (e.offsetX < modalBorderSize) {
        this.modalPosition = e.x;
        document.addEventListener('mousemove', this.boundResize, false);
      }
    }, false);

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', this.boundResize, false);
    }, false);
  }

  resize(e) {
    const dx = this.modalPosition - e.x;
    this.modalPosition = e.x;
    this.modal.style.width = `${parseInt(getComputedStyle(this.modal, '').width) + dx}px`;
  }
}
