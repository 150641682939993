/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';

const counterSentence = 'mandat(s) sélectionné(s)';

export default class AssistantSelectAllController extends Controller {
  static targets = ['toggler', 'checkBox', 'allToggler', 'counterText'];

  static outlets = ['tableRow'];

  connect() {
    this.counter = 0;
    this.setAllTogglerState();
  }

  setCounter() {
    if (this.hasCounterTextTarget) {
      this.counter = this.checkBoxTargets.filter((checkbox) => checkbox.checked).length;
      if (this.counter > 0) {
        this.counterTextTarget.innerText = `${this.counter} ${counterSentence}`;
      } else {
        this.counterTextTarget.innerText = '';
      }
    }
  }

  setAllTogglerState() {
    if (this.allChecked()) {
      this.allTogglerTarget.checked = true;
      this.allTogglerTarget.indeterminate = false;
    } else if (this.someChecked()) {
      this.allTogglerTarget.checked = false;
      this.allTogglerTarget.indeterminate = true;
    } else {
      this.allTogglerTarget.checked = false;
      this.allTogglerTarget.indeterminate = false;
    }
    this.setCounter();
  }

  checkBoxTargetConnected(checkBox) {
    const { isLast } = checkBox.dataset;
    if (isLast !== 'true') return;

    this.setAllTogglerState();
  }

  checkBoxTargetDisconnected() {
    this.setAllTogglerState();
  }

  checkBoxChanged() {
    this.setAllTogglerState();
  }

  toggleAll() {
    if (this.allChecked()) {
      this.uncheckAll();
    } else {
      this.checkAll();
    }
  }

  someChecked() {
    return this.checkBoxTargets.some((checkBox) => checkBox.checked);
  }

  allChecked() {
    return this.checkBoxTargets.every((checkBox) => checkBox.checked);
  }

  allUnchecked() {
    return this.checkBoxTargets.every((checkBox) => !checkBox.checked);
  }

  checkAll() {
    this.checkBoxTargets.forEach((checkBox, index) => {
      checkBox.checked = true;
    });

    this.allTogglerTarget.checked = true;
    this.setCounter();
  }

  uncheckAll() {
    this.checkBoxTargets.forEach((checkBox, index) => {
      checkBox.checked = false;
    });

    this.allTogglerTarget.checked = false;
    this.setCounter();
  }
}
