import { Turbo } from '@hotwired/turbo-rails';
import { getMeta } from './utils';

// const objectToFormData = (data, formData, index = '') => {
//   for (const objKey in data) {
//     const currentValue = data[objKey];
//     const currentIndex = index ? `${index}[${objKey}]` : objKey;

//     // Verify that currentValue is not an array, as arrays are also objects in js
//     if (typeof currentValue === 'object' && !Array.isArray(currentValue)) {
//       // If the currently iterated object is an empty object, we must not append it to the
//       // formData, as that one will get appended as empty JSON object string too. In that case, as even null will
//       // get parsed to "null" with formData, add a 'none' string as the value, and handle the
//       // respective cases on the server side
//       if (Object.keys(currentValue).length === 0) {
//         formData.append(currentIndex, 'none'); // You may change this to however you wanna handle empty objects
//         continue;
//       }

//       objectToFormData(currentValue, formData, currentIndex);
//     } else {
//       formData.append(currentIndex, currentValue);
//     }
//   }
// };

// Nous permet d'envoyer des requeêts turbo-stream
// depuis tu javascript, en passant un objet JS en paramètre "body"
//
// Cette fonction a été écrite ENTIEREMENT par Chatgpt ! 
// La précédente ne tenait pas compte des Array, celle-ci le fait... Impec
const objectToFormData = (obj, formData = new FormData(), parentKey = null) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const formKey = parentKey ? `${parentKey}[${key}]` : key;

      if (value instanceof File) {
        formData.append(formKey, value);
      } else if (value instanceof Blob) {
        formData.append(formKey, value, key);
      } else if (value instanceof Date) {
        formData.append(formKey, value.toISOString());
      } else if (value instanceof Array) {
        // Gère les tableaux
        for (let i = 0; i < value.length; i++) {
          const arrayKey = `${formKey}[${i}]`;
          if (value[i] instanceof Object) {
            objectToFormData(value[i], formData, arrayKey);
          } else {
            formData.append(arrayKey, value[i]);
          }
        }
      } else if (typeof value === 'object' && !(value instanceof Array)) {
        // Gère les objets
        objectToFormData(value, formData, formKey);
      } else {
        formData.append(formKey, value);
      }
    }
  }
  return formData;
}

export default class TurboRequest {
  constructor(url, body, method = 'POST') {
    this.url = url;
    this.body = body;
    this.method = method;
  }

  call(callback) {
    const opts = {
      method: this.method,
      headers: this.requestHeaders,
    };

    if (this.method !== 'GET') {
      opts.body = this.formData;
    }

    fetch(
      this.url,
      opts,
    ).then((r) => r.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        if (typeof callback === 'function') callback();
      });
  }

  set body(val) {
    this._bodyObj = val;
    this.formData = this.buildFormData();
  }

  get bodyObj() {
    return this._bodyObj;
  }

  set formData(val) {
    this._formData = val;
  }

  get formData() {
    return this._formData;
  }

  get requestHeaders() {
    return {
      Accept: 'text/vnd.turbo-stream.html',
    };
  }

  get csrfParam() {
    return getMeta('csrf-param');
  }

  get csrfToken() {
    return getMeta('csrf-token');
  }

  buildFormData() {
    if (this.method === 'GET') return {};
    const formData = new FormData();
    formData.append(this.csrfParam, this.csrfToken);

    objectToFormData(this.bodyObj, formData);
    return formData;
  }
}
