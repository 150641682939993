const CHART_CLICK_DESTINATION_BASE_PATH = '/explore/';

export const smoothScrollTo = (targetId, offset = 0) => {
  const element = document.getElementById(targetId);

  if (offset !== 0) {
    element.style.scrollMarginTop = `${offset}px`;
  }

  element.scrollIntoView({
    behavior: 'smooth',
  });
};

export const draggedElementStore = {
  draggedElement: null,
  isNomenclatureCode() {
    return this.draggedElement?.classList?.contains('mix-screen-code');
  },
  isMinimapItem() {
    return this.draggedElement?.classList?.contains('mix-screen-mini-map__group-item');
  },
};

export const getCSSColor = (colorName) => getComputedStyle(document.documentElement)
  .getPropertyValue(`--bs-${colorName}`).trim();

const getCurrentSearchParams = () => window.location.search;

const buildChartClickDestination = (path, removeGroupByClause = true, legalStateFilter = null) => {
  const urlWithoutSearchParams = CHART_CLICK_DESTINATION_BASE_PATH + path;
  const currentSearchParamsString = getCurrentSearchParams();
  const currentSearchParams = new URLSearchParams(currentSearchParamsString);

  if (removeGroupByClause && currentSearchParams.has('group_by')) {
    currentSearchParams.delete('group_by');
  }

  if (legalStateFilter != null) {
    currentSearchParams.set('legal_state', legalStateFilter);
  }
  return `${urlWithoutSearchParams}?${currentSearchParams.toString()}`;
};

export function getCurrentUserId() {
  return document.querySelector('meta[name="current_user"]').getAttribute('id');
}

const euroFormatter = (val) => val.toLocaleString('fr-FR', {
  style: 'currency',
  currency: 'EUR',
});

const defaultFormatter = (val) => val;

const chartLocale = {
  defaultLocale: 'fr',
  locales: [{
    name: 'fr',
    options: {
      months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      shortMonths: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juill', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
      days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      shortDays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      toolbar: {
        download: 'Download SVG',
        selection: 'Selection',
        selectionZoom: 'Selection Zoom',
        zoomIn: 'Zoom In',
        zoomOut: 'Zoom Out',
        pan: 'Panning',
        reset: 'Reset Zoom',
      },
    },
  }],
};

const getMeta = (name) => {
  const element = document.head.querySelector(`meta[name="${name}"]`);
  return element && element.getAttribute('content');
};

const loaderTemplate = `
  <div class="crtp-loader">
    <div class="crtp-loader__spinner"></div>
  </div>`;

const getColorsFromLegalStates = (legalStates) => legalStates.map((legalState) => {
  const state = legalState.toLowerCase();
  console.log('state', state);
  let color;
  switch (state) {
    case 'secured':
    case 'sécurisé':
      color = getCSSColor('primary');
      break;
    case 'risky':
    case 'risqué':
      color = getCSSColor('accent1');
      break;
    case 'untreated':
    case 'hors commande publique':
      color = getCSSColor('gray-500');
      break;
    default:
      color = getCSSColor('primary');
  }

  return color;
});

// Date string should be: dd/mm/YYYY
const dateFromLocaleString = (dateString) => {
  const [day, month, year] = dateString.split('/');
  return new Date(`${year}-${month}-${day}`);
};

const daysBetween = (date1, date2) => {
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between
  // two dates
  // Il faut ajouter 1 pour compter le premier jour comme un jour
  return Math.round(diffInTime / (1000 * 3600 * 24));
};

export {
  buildChartClickDestination,
  euroFormatter,
  defaultFormatter,
  getCurrentSearchParams,
  chartLocale,
  getColorsFromLegalStates,
  getMeta,
  loaderTemplate,
  dateFromLocaleString,
  daysBetween,
};
