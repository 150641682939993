/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import TurboRequest from '../../../turbo_request';

export default class extends Controller {
  static targets = ['counter', 'toggler', 'selectableCode', 'mergeBtn', 'mirrorBtn', 'perimeterFrame', 'refresher'];

  static values = {
    mergeUrl: String,
    mirrorUrl: String,
  };

  connect() {
    this.shownIds = [];
    this.selectedIds = [];

    this.counterValue = 0;
  }

  toggleToPerimetersScreen(event) {
    event.preventDefault();
    const codeId = parseInt(event.target.dataset.codeId, 10);
    const codeIds = [...this.shownIds];
    const index = codeIds.indexOf(codeId);

    if (index === -1) {
      codeIds.push(codeId);
    } else {
      codeIds.splice(index, 1);
    }

    this.refreshWithCodeIds(codeIds);
  }

  refresherTargetConnected(element) {
    const codeIds = [...this.shownIds];
    // AUcun code affiché dans le mélangeur, donc rien à rafraichir
    if (codeIds.length === 0) {
      this.refresherTarget.remove();
      return;
    }

    const appendIds = element.dataset.append?.split(',')?.map((codeIdString) => parseInt(codeIdString, 10));
    appendIds.forEach((codeId) => {
      const index = codeIds.indexOf(codeId);
      if (index === -1) {
        codeIds.push(codeId);
      }
    });

    const removeIds = element.dataset.remove?.split(',')?.map((codeIdString) => parseInt(codeIdString, 10));
    removeIds.forEach((codeId) => {
      const index = codeIds.indexOf(codeId);
      if (index > -1) {
        codeIds.splice(index, 1);
      }
    });

    this.refreshWithCodeIds(codeIds);

    this.refresherTarget.remove();
  }

  refreshWithCodeIds(codeIds) {
    const currentURL = new URL(this.perimeterFrameTarget.getAttribute('src'));
    currentURL.searchParams.set('code_ids', codeIds.join(','));
    this.perimeterFrameTarget.setAttribute('src', currentURL.toString());
  }

  selectableCodeTargetConnected(element) {
    const codeId = parseInt(element.dataset.codeId, 10);
    this.addToShownIds(codeId);
    this.counterValue += 1;

    this.toggleActiveClassOnTogglers();
  }

  selectableCodeTargetDisconnected(element) {
    const codeId = parseInt(element.dataset.codeId, 10);
    this.counterValue -= 1;
    this.removeFromShownIds(codeId);
    this.removeFromSelectedIds(codeId);
    this.toggleMergeBtn();
    this.toggleActiveClassOnTogglers();
  }

  toggleActiveClassOnTogglers() {
    this.togglerTargets.forEach((toggler) => {
      if (this.shownIds.includes(parseInt(toggler.dataset.codeId, 10))) {
        toggler.classList.add('mix-screen-code__footer-btn--active');
      } else {
        toggler.classList.remove('mix-screen-code__footer-btn--active');
      }
    });
  }

  mergeRequest(e) {
    e.preventDefault();
    const url = new URL(this.mergeUrlValue);
    url.searchParams.append('code_ids', this.selectedIds.join(','));

    Turbo.visit(url.toString(), { frame: 'side_panel' });
  }

  mirrorRequest(e) {
    e.preventDefault();
    const url = new URL(this.mirrorUrlValue);

    const turboRequest = new TurboRequest(url, {
      mirror: {
        code_ids: this.selectedIds.join(','),
      },
    });

    turboRequest.call();
  }

  toggleSelection(e) {
    const checkbox = e.target;
    const { codeId } = e.params;

    if (checkbox.checked) {
      this.addToSelectedIds(codeId);
    } else {
      this.removeFromSelectedIds(codeId);
    }

    this.toggleActionBtns();
  }

  toggleActionBtns() {
    this.toggleMergeBtn();
    this.toggleMirrorBtn();
  }

  toggleMergeBtn() {
    if (this.selectedIds.length >= 2) {
      this.mergeBtnTarget.classList.remove('disabled');
    } else {
      this.mergeBtnTarget.classList.add('disabled');
    }
  }

  toggleMirrorBtn() {
    if (this.selectedIds.length === 2) {
      this.mirrorBtnTarget.classList.remove('disabled');
    } else {
      this.mirrorBtnTarget.classList.add('disabled');
    }
  }

  addToSelectedIds(codeId) {
    this.selectedIds.push(codeId);
  }

  removeFromSelectedIds(codeId) {
    const index = this.selectedIds.indexOf(codeId);
    if (index !== -1) {
      this.selectedIds.splice(index, 1);
    }
  }

  addToShownIds(codeId) {
    this.shownIds.push(codeId);
  }

  removeFromShownIds(codeId) {
    const index = this.shownIds.indexOf(codeId);
    if (index !== -1) {
      this.shownIds.splice(index, 1);
    }
  }

  set counterValue(val) {
    this._counterValue = val;
    if (val > 0) {
      this.counterTarget.innerHTML = val;
      this.counterTarget.style.display = 'flex';
    } else {
      this.counterTarget.style.display = 'none';
    }
  }

  get counterValue() {
    return this._counterValue;
  }
}
