import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';

export default class extends Controller {
  options = {
    rootMargin: '50px',
    eventPrefix: false,
  };

  connect() {
    useIntersection(this, this.options);
  }

  appear() {
    this.clickSelf();
  }

  clickSelf() {
    this.element.click();
    this.element.style.display = 'none';
  }
}
