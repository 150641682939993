import { Toast } from 'bootstrap/dist/js/bootstrap.esm';

export default class Notification {
  constructor(style, content) {
    this.classes = [];
    switch (style) {
      case 'notice':
        this.classes = ['text-white bg-primary'];
        break;
      case 'alert':
        this.classes = ['text-white bg-danger'];
        break;
      case 'keyboard':
        this.classes = ['notification--keyboard-shortcut border border-primary border-opacity-25'];
        break;
      default:
        break;
    }
    const { title, body } = content;
    this.title = title;
    this.body = body;
  }

  show() {
    const container = document.getElementById('toast-container') || document.body;
    const element = this.templateWithTitle.length
      ? this.templateWithTitle
      : this.templateWithoutTitle;

    container.appendChild(element);

    this.toast = new Toast(element, {});
    this.toast.show();
  }

  get templateWithTitle() {
    const string = `
      <div class="toast top-0 end-0 ${this.classes.join(' ')}" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          ${this.title}
        </div>
        <div class="toast-body">
          ${this.body}
        </div>
      </div>
    `;

    return this.htmlToElement(string);
  }

  get templateWithoutTitle() {
    const string = `
      <div class="toast top-0 end-0 ${this.classes.join(' ')}" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex justify-content-between">
          <div class="toast-body">
            ${this.body}
          </div>
          <button type="button" tabindex="-1" class="btn-close bg-transparent text-white p-3" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    `;

    return this.htmlToElement(string);
  }

  htmlToElement(html) {
    const template = document.createElement('template');
    template.innerHTML = html.trim();
    return template.content.childNodes[0];
  }
}
