/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';
import { draggedElementStore } from '../../../utils';

const droppableClass = 'mix-screen-code--droppable';
const notDroppableClass = 'mix-screen-code--not-droppable';
const selectedClass = 'mix-screen-code--selected';

const selectableIconNotSelectedClass = 'bi-square';
const selectableIconSelectedClass = 'bi-check-square';

export default class extends Controller {
  static targets = ['item', 'selectableIcon'];

  static values = {
    selectable: {
      type: Boolean,
      default: false,
    },
  };

  initialize() {
    this.dragEnterHandler = this.onDragEnter.bind(this);
    this.connectedItemIds = this.itemTargets.map(
      (itemElement) => Number(itemElement.dataset.assistantMixConnectedItemItemIdValue),
    );

    if (this.selectableValue) {
      this.selected = false;
    }
  }

  connect() {
    this.element.addEventListener('dragenter', this.dragEnterHandler);
  }

  onDragEnter(event) {
    // Prevent user from dropping invalid items on a code
    if (draggedElementStore.isNomenclatureCode() || draggedElementStore.isMinimapItem()) return;
    // event.preventDefault();
    this.connectedItemsElement = this.element.querySelector('[data-content-toggler-target="content"]');
    if (!this.connectedItemsElement.classList.add('js-active')) {
      this.connectedItemsElement.classList.add('js-active');
    }
  }

  onNomenclatureItemDragstarted(event) {
    const { itemId } = event.detail;
    if (this.isItemDroppable(itemId)) {
      this.element.classList.add(droppableClass);
    } else {
      this.element.classList.add(notDroppableClass);
    }
  }

  onNomenclatureItemDragended() {
    this.element.classList.remove(droppableClass, notDroppableClass);
  }

  isItemDroppable(itemId) {
    return !this.connectedItemIds.includes(itemId);
  }

  toggleSelection(e) {
    const checkbox = e.target;
    if (checkbox.checked) {
      this.selected = true;
    } else {
      this.selected = false;
    }
  }

  set selected(bool) {
    this._selected = bool;
    if (bool) {
      this.element.classList.add(selectedClass);
      this.selectableIconTarget.classList.remove(selectableIconNotSelectedClass);
      this.selectableIconTarget.classList.add(selectableIconSelectedClass);
    } else {
      this.element.classList.remove(selectedClass);
      this.selectableIconTarget.classList.add(selectableIconNotSelectedClass);
      this.selectableIconTarget.classList.remove(selectableIconSelectedClass);
    }
  }

  get selected() {
    return this._selected;
  }
}
