/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';
import * as XLSX from 'xlsx';

export default class extends Controller {
  static targets = [];

  static values = {
    nomenclatureId: Number,
  };

  connect() {
  }

  export(event) {
    event.preventDefault();

    fetch(
      `/nomenclatures/${this.nomenclatureIdValue}/export`,
      {
        headers: {
          Accept: 'text/csv',
        },
      },
    )
      .then((response) => response.text())
      .then((response) => {
        const wb = XLSX.read(response, {
          type: 'string', raw: true, dense: true, cellStyles: true,
        });
        XLSX.writeFile(wb, 'Nomenclature.xlsx', { bookSST: true, cellStyles: true });
      });
  }

  cirilExport() {
    const baseUrl = `/nomenclatures/${this.nomenclatureIdValue}/ciril_export`;
    const request1 = fetch(`${baseUrl}?file=descriptif`).then((response) => response.text());
    const request2 = fetch(`${baseUrl}?file=families`).then((response) => response.text());
    const request3 = fetch(`${baseUrl}?file=nomenclature`).then((response) => response.text());

    const workbook = XLSX.utils.book_new();

    Promise.all([request1, request2, request3])
      .then(([file1, file2, file3]) => {
        const wb1 = XLSX.read(file1, {
          type: 'string', raw: true, dense: true, cellStyles: true,
        });

        const wb2 = XLSX.read(file2, {
          type: 'string', raw: true, dense: true, cellStyles: true,
        });

        const wb3 = XLSX.read(file3, {
          type: 'string', raw: true, dense: true, cellStyles: true,
        });

        const descriptifSheet = wb1.Sheets[wb1.SheetNames[0]];
        XLSX.utils.book_append_sheet(workbook, descriptifSheet, 'descriptif');

        const familiesSheet = wb2.Sheets[wb2.SheetNames[0]];
        XLSX.utils.book_append_sheet(workbook, familiesSheet, 'ffamille.txt');

        const nomenclatureSheet = wb3.Sheets[wb3.SheetNames[0]];
        XLSX.utils.book_append_sheet(workbook, nomenclatureSheet, 'fnommar.txt');

        XLSX.writeFile(workbook, 'Ciril.xlsx', { bookSST: true, cellStyles: true });
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
