import tippy from 'tippy.js';

import {
  toggleCurrentDateMarker,
  addDaysToDate,
} from './step_drag_resize_utils';

import { dateFromLocaleString } from '../../utils';

import CollisionHandler from './collision_handler';

export default class StepDragger {
  constructor(element) {
    this.element = element;
    this.scrollWraper = document.getElementById('roadmap_scroller');

    this.setupListeners();
  }

  setupListeners() {
    this.dragMoveHandler = this.dragMove.bind(this);
    this.dragStopHandler = this.dragStop.bind(this);

    this.element.addEventListener('mousedown', (event) => this.mouseDown(event));
  }

  mouseDown(event) {
    if (event.button !== 0) return;
    // Ne pas movesi on est déjà en train de resize
    if (this.element.classList.contains('roadmap-step--dragging')) return;

    this.emitEvent('drag-start');

    this.collisionHandler = new CollisionHandler(this.element);

    this.shouldMagnetizeOnRightEdge = true;

    this.initialScreenX = event.screenX; // Poisition de la souris au moment du mousedown
    this.previousMouseScreenX = event.screenX;
    this.initialElementStartX = parseFloat(this.element.dataset.x);
    this.initialElementEndX = this.initialElementStartX + this.element.offsetWidth;

    // Si au début du drag l'élément est déjà en dehors de la zone visible, il ne faut pas
    // fixer l'élément au bord droit immédiatement
    if (this.initialElementEndX > (this.scrollWraper.scrollLeft + this.scrollWraper.offsetWidth)) {
      this.shouldMagnetizeOnRightEdge = false;
    }

    this.duration = parseFloat(this.element.dataset['planner-RoadmapStepDurationValue']);

    this.tipy = tippy(this.element, {
      placement: 'top-start',
      arrow: false,
      allowHTML: true,
      trigger: 'manual',
    });

    document.addEventListener('mouseup', this.dragStopHandler);
    document.addEventListener('mousemove', this.dragMoveHandler);
  }

  // Lorsqu'on déplace l'élément this.element
  //
  // Si le bord gauche de this.element touche la limite gauche de la partie scrollable
  //  - Fixer l'élément à cet emplacemennt tant qu'on chercher à le déplacer sur la gauche
  //  - scroller scrollable
  dragMove(event) {
    if (event.buttons === 1) {
      // Ne pas movesi on est aussi en train de resize
      if (this.element.classList.contains('roadmap-step--resizing')) return;

      this.element.classList.add('roadmap-step--dragging');

      event.preventDefault();
      this.drag = true;

      // Stocke la position du bord gauche et du bord droit de la partie scrollable:
      // this.element ne peut aller au delà sur la gauche
      this.leftEdgePosition = this.scrollWraper.scrollLeft;
      this.rightEdgePosition = this.scrollWraper.scrollLeft + this.scrollWraper.offsetWidth;

      // Nombre de pixels du déplacement ( négatif = vers la fauche, positif = vers la droite)
      const movementDelta = event.screenX - this.previousMouseScreenX;
      this.direction = movementDelta >= 0 ? 'right' : 'left';

      // La nouvelle position de this.element = sa position au moment du mouseDown + le nb de pixel de déplacement
      this.newElementPosition = this.initialElementStartX + movementDelta;

      // Si la position de l'élément est <= au bord gauche, alors sa position devient
      // le bord gauche, on ne peut aller delà
      // Si la position du bord droit de l'élément est supérier à la limite droite,
      // sa position devient rightEdgePosition - elementWidth
      // Dans les autres cas on le déplace normalement
      if (this.newElementPosition <= this.leftEdgePosition && this.direction === 'left') {
        this.newElementPosition = this.leftEdgePosition;
        this.magnetizeOnLeftEdge();
      } else if (this.newElementPosition + this.element.offsetWidth >= this.rightEdgePosition - 2 && this.direction === 'right' && this.shouldMagnetizeOnRightEdge) {
        this.newElementPosition = this.rightEdgePosition - this.element.offsetWidth;
        this.magnetizeOnRightEdge();
      } else {
        // On déplace this.element du même nombre de pixels
        Object.assign(this.element.style, {
          transform: `translateX(${this.newElementPosition}px)`,
        });

        this.initialElementStartX = this.newElementPosition;
        this.initialElementEndX = this.newElementPosition + this.element.offsetWidth;
      }

      this.currentDateMarker = this.activateCurrentDateMarker();

      this.updateTipy();

      this.collisionHandler.checkAndHandleCollision(
        this.direction === 'left',
        this.direction === 'right', // Observer le côté gauche si on se déplace du côté gauche
      );

      this.previousMouseScreenX = event.screenX;
    }
  }

  activateCurrentDateMarker() {
    // On identifie le dateMarker sur lequel le bord gauche de this.element se trouve
    // en créant un petit rectanble pour reproduire le bord gauche de this.element
    const elementLeftEdgeAfterMove = {
      left: this.newElementPosition - 1,
      right: this.newElementPosition,
    };
    return toggleCurrentDateMarker(elementLeftEdgeAfterMove);
  }

  magnetizeOnLeftEdge() {
    Object.assign(this.element.style, {
      transform: `translateX(${this.newElementPosition - 10}px)`,
    });
    this.scrollWraper.scrollLeft -= 10;
    this.initialElementStartX = this.newElementPosition - 10;
  }

  magnetizeOnRightEdge() {
    Object.assign(this.element.style, {
      transform: `translateX(${this.newElementPosition + 10}px)`,
    });
    this.scrollWraper.scrollLeft += 10;
    this.initialElementStartX = this.newElementPosition + 10;
  }

  dragStop() {
    if (this.drag === true) {
      // Il faut maintenant placer définitivement l'élément
      // sur le bord de currentDateMarker
      const finalPosition = parseFloat(this.currentDateMarker.dataset.screenX);

      Object.assign(this.element.style, {
        transform: `translateX(${finalPosition}px)`,
      });

      Object.assign(this.element.dataset, {
        x: finalPosition,
      });

      // Il se peut que la position finale soit inférieure à la partie autorisée,
      // il faut scroller un peu
      if (finalPosition < this.leftEdgePosition) {
        this.scrollWraper.scrollLeft -= this.leftEdgePosition - finalPosition;
      }

      this.emitEvent('dragged', {
        startOn: this.startOn,
        endOn: this.endOn,
      });

      [...document.querySelectorAll('[data-screen-x]')].forEach((dateMarkerElement) => {
        dateMarkerElement.classList.remove('active', 'active--left', 'active--right');
      });

      this.destroyTipy();
      this.element.classList.remove('roadmap-step--dragging');
    }

    document.removeEventListener('mousemove', this.dragMoveHandler);
    document.removeEventListener('mouseup', this.dragStopHandler);

    this.drag = false;
  }

  updateTipy() {
    // Update le tooltip
    this.tipy.setContent(`<small>${this.startOn.toLocaleDateString()} - ${this.duration} jours</small>`);
    this.tipy.show();
  }

  destroyTipy() {
    this.tipy.destroy();
  }

  emitEvent(eventName, details) {
    const event = new CustomEvent(`step-dragger:${eventName}`, { detail: details });
    this.element.dispatchEvent(event);
  }

  get startOn() {
    return dateFromLocaleString(this.currentDateMarker.dataset.date);
  }

  get endOn() {
    return addDaysToDate(this.startOn, this.duration);
  }
}
