/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';

const findPos = (obj) => {
  const curleft = curtop = 0;
};

export default class extends Controller {
  static targets = ['note', 'nextBtn'];

  connect() {
    this.lastVisibleIndex = 1;

    this.showVisibles();
    this.toggleBtn();
  }

  showVisibles() {
    this.noteTargets.slice(0, this.lastVisibleIndex).forEach((noteElement) => {
      noteElement.classList.add('themis-panel-show__note--visible');
    });
    this.scroll();
  }

  scroll() {
    const el = this.noteTargets[this.lastVisibleIndex - 1];
    const topPosition = el.offsetTop;
    const panel = document.getElementById('themis-panel');
    panel.scrollTo({
      left: 0,
      top: topPosition,
      behavior: 'smooth',
    });
  }

  showNext(e) {
    e.preventDefault();
    this.lastVisibleIndex += 1;
    this.showVisibles();
    this.toggleBtn();
  }

  toggleBtn() {
    if (this.lastVisibleIndex === this.noteTargets.length) {
      this.nextBtnTarget.style.display = 'none';
    }
  }
}
