import { Controller } from '@hotwired/stimulus';

const formatNumber = (number) => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(
  number,
);

const formatEngagementAdjective = (index) => {
  switch (index) {
    case 0:
      return 'quadriennal';
    case 1:
      return 'triennal';
    case 2:
      return 'biennal';
    case 3:
      return 'annuel';
    default:
      console.log(`Sorry, we are out of ${expr}.`);
  }
};

const generatePricingHtml = (pricings) => pricings.map((pricing, index) => `
      <div class="${index === 0 ? 'mb-2' : ''}">
        <span class="tabular-nums ${index === 0 ? 'fw-bold  fs-4' : 'text-white text-opacity-75'}">
          ${formatNumber(pricing)}
        </span>
        <small class="text-white text-opacity-75">
          HT / an pour un contrat ${formatEngagementAdjective(index)}
        </small>
      </div>
    `).join('');

export default class extends Controller {
  static targets = ['slider', 'budget', 'pricing'];

  connect() {
    this.calculateBudget(this.sliderTarget.value);
    this.calculatePricing();
  }

  calculateBudget(sliderVal) {
    this.budget = sliderVal * 10000000;
  }

  displayBudget(event) {
    const { value } = event.target;
    this.calculateBudget(value);
  }

  // Construit un tableau avec 4 prix: un pour chaque possibilités de durée d'engagement
  // => engagement annuel => pricing[0]
  // => engagement biennal => pricing[1]
  // etc..
  calculatePricing() {
    // =SI(C6<100000000; 0,00005*C6; SI(C6>=500000000; 9000 + (C6-500000000) * 0,00001; 0,00005*C6 - 0,00004*(C6-100000000)))
    if (this.budget < 100000000) {
      this.basePricing = 0.00005 * this.budget < 1500 ? 1500 : 0.00005 * this.budget ;
    } else if (this.budget >= 500000000) {
      const base = 9000 + (this.budget - 500000000) * 0.00001
      this.basePricing = base > 11700 ? 11700 : base;
    } else {
      this.basePricing = 0.00005 * this.budget - 0.00004 * (this.budget - 100000000);
    }

    const pricings = [];
    for (let i = 0; i <= 15; i += 5) {
      pricings.push(this.basePricing * (1 - (i / 100)));
    }

    this.pricings = pricings.reverse();
  }

  set budget(val) {
    this._budget = val;
    this.budgetTarget.innerText = formatNumber(val);
  }

  get budget() {
    return this._budget;
  }

  set pricings(array) {
    this.pricingTarget.innerHTML = generatePricingHtml(array);
  }
}
