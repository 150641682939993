import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['radioBtn'];

  connect() {}

  checkRadioBtn(event) {
    this.radioBtnTarget.checked = true;
  }
}
