/* eslint-disable no-new */
/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['code'];

  connect() {
  }
}
