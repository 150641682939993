import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import ApexCharts from 'apexcharts';
import {
  getCSSColor,
  buildChartClickDestination,
  euroFormatter,
} from '../../utils';
// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  static targets = ['chart'];

  static values = {
    series: Array,
    frLabels: Array,
    color: {
      type: String,
      default: 'primary',
    },
    barClickable: {
      type: Boolean,
      default: false,
    },
    xAxisLabelClickable: {
      type: Boolean,
      default: true,
    },
  };

  initialize() {
    this.color = getCSSColor(this.colorValue);
    this.idealChartHeight = 400;

    this.tickAmount = Math.max(...this.seriesValue.map((s) => s.y)) + 2;
  }

  connect() {
    this.options = {
      chart: {
        type: 'bar',
        height: this.idealChartHeight,
        events: {
          dataPointSelection: (event, chartContext, opts) => {
            if (this.barClickableValue) {
              window.scrollTo(0, 0); // Obligé d'ajouter ça sinon le scroll est préservé
              Turbo.visit(destination);
            }
          },
          xAxisLabelClick: (event, chartContext, opts) => {
            if (this.xAxisLabelClickableValue) {
              window.scrollTo(0, 0); // Obligé d'ajouter ça sinon le scroll est préservé
              Turbo.visit(destination);
            }
          },
        },
      },
      yaxis: {
        max: this.tickAmount,
        tickAmount: this.tickAmount,
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#333'],
        },
        offsetY: -27,
      },
      plotOptions: {
        bar: {
          barHeight: '10%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      xaxis: {
        tickPlacement: 'on',
      },
      series: [{
        name: 'Nombre de marchés qui arrivent à leur terme',
        data: this.seriesValue,
      }],
      colors: this.color,
      fill: {
        backgroundColor: this.primaryColor,
      },
    };

    const chart = new ApexCharts(this.chartTarget, this.options);
    chart.render();
  }
}
