export default class ConfirmDialog {
  constructor(id) {
    this.dialogElementId = id;
    this.dialogElement = document.getElementById(this.dialogElementId);
    this.questionElementId = `${this.dialogElementId}-question`;

    if (!this.dialogElement) {
      this.dialogElement = document.createElement('dialog');
      this.dialogElement.id = this.dialogElementId;

      this.buildDialog();
      this.listenToClose();

      document.body.append(this.dialogElement);
    }
  }

  on(eventName, callback) {
    this.dialogElement.addEventListener(`confirm-dialog:${eventName}`, () => callback());
  }

  listenToClose() {
    this.dialogElement.addEventListener('close', (e) => {
      let event;
      if (this.dialogElement.returnValue === 'confirm') {
        event = new Event('confirm-dialog:confirm');
      } else if (this.dialogElement.returnValue === 'cancel') {
        event = new Event('confirm-dialog:cancel');
      }

      this.dialogElement.dispatchEvent(event);
      this.dialogElement.remove();
    });
  }

  buildDialog() {
    this.dialogElement.classList.add('p-4');

    const headerElement = document.createElement('h1');
    headerElement.classList.add('fs-5');
    headerElement.innerText = 'Confirmation';
    this.dialogElement.append(headerElement);

    const questionElement = document.createElement('span');
    questionElement.id = this.questionElementId;
    questionElement.innerHTML = 'Question';
    this.dialogElement.append(questionElement);

    const formElement = document.createElement('form');
    formElement.classList.add('mt-4', 'hstack', 'gap-2');
    formElement.method = 'dialog';

    const confirmBtn = document.createElement('button');
    confirmBtn.classList.add('btn', 'btn-primary');
    confirmBtn.innerText = 'Poursuivre';
    confirmBtn.value = 'confirm';

    const cancelBtn = document.createElement('button');
    cancelBtn.classList.add('btn', 'btn-light');
    cancelBtn.innerText = 'Annuler';
    cancelBtn.value = 'cancel';

    formElement.append(confirmBtn);
    formElement.append(cancelBtn);
    this.dialogElement.append(formElement);
  }

  show() {
    this.dialogElement.showModal();
  }

  set question(sentence) {
    const questionElement = document.getElementById(this.questionElementId);
    questionElement.innerHTML = sentence;
  }
}
