/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';

export default class NomenclatureCodeForm extends Controller {
  static targets = ['familyRadioBtn', 'crtpDispenseTypeRadioBtn', 'legalThresholdAmountInput', 'subjectToCompetitionCheckBox'];

  static values = {
    cartoapV1Numerotation: Boolean,
  };

  connect() {
    this.defaultLegalThreshold = 40000;

    if (this.cartoapV1NumerotationValue) {
      this.selectedFamilyElement = this.familyRadioBtnTargets.find(
        (element) => element.checked,
      );

      if (this.currentCrtpDispenseTypeBtn) {
        this.defaultLegalThreshold = parseInt(this.currentCrtpDispenseTypeBtn.dataset.defaultLegalThreshold, 10);
        this.toggleSubjectToCompetition();
        this.toggleCrtpDispenseTypeRadioBtns();
      } else if (this.selectedFamilyElement) {
        this.toggleCrtpDispenseTypeRadioBtns();
        this.toggleSubjectToCompetition();
      }
    }
  }

  onFamilyChange(event) {
    if (this.cartoapV1NumerotationValue) {
      this.availableCrtpDispenseTypes = JSON.parse(event.target.dataset.availableCrtpDispenseTypes);
      this.familySubjectToCompetition = event.target.dataset?.cartoapV1SubjectToCompetition === 'true';

      this.toggleCrtpDispenseTypeRadioBtns();
      this.toggleSubjectToCompetition();
    }
  }

  onSubjectToCompetitionChange() {
    this.toggleLegalThresholdAmountInput();
  }

  onCrtpDispenseTypeChange(event, radioBtn) {
    let target;
    if (event) {
      target = event.target;
    } else if (radioBtn) {
      target = radioBtn;
    }
    this.defaultLegalThreshold = parseInt(target.dataset.defaultLegalThreshold, 10) || null;
    this.toggleLegalThresholdAmountInput();
  }

  toggleLegalThresholdAmountInput() {
    if (this.codeSubjectToCompetition) {
      this.legalThresholdAmountInputTarget.readOnly = false;
      this.legalThresholdAmountInputTarget.value = this.defaultLegalThreshold;
    } else {
      this.legalThresholdAmountInputTarget.readOnly = true;
      this.legalThresholdAmountInputTarget.value = '';
    }
  }

  toggleCrtpDispenseTypeRadioBtns() {
    if (this.currentCrtpDispenseTypeBtn && this.availableCrtpDispenseTypes.includes(this.currentCrtpDispenseTypeBtn.value)) {
      this.crtpDispenseTypeRadioBtnTargets.filter((btn) => btn != this.currentCrtpDispenseTypeBtn).forEach((radioBtn) => {
        radioBtn.disabled = !this.availableCrtpDispenseTypes.includes(radioBtn.value);
        radioBtn.checked = false;
      });
    } else {
      this.crtpDispenseTypeRadioBtnTargets.forEach((radioBtn) => {
        radioBtn.disabled = !this.availableCrtpDispenseTypes.includes(radioBtn.value);
        radioBtn.checked = this.availableCrtpDispenseTypes[0] === radioBtn.value;
        if (radioBtn.checked) {
          this.onCrtpDispenseTypeChange(null, radioBtn);
        }
      });
    }
  }

  toggleSubjectToCompetition() {
    this.subjectToCompetitionCheckBoxTarget.checked = this.familySubjectToCompetition;
    this.subjectToCompetitionCheckBoxTarget.disabled = !this.familySubjectToCompetition;
    this.onSubjectToCompetitionChange();
  }

  set selectedFamilyElement(element) {
    this._selectedFamilyElement = element;
    if (element) {
      this.availableCrtpDispenseTypes = JSON.parse(element.dataset.availableCrtpDispenseTypes);
      this.familySubjectToCompetition = element.dataset?.cartoapV1SubjectToCompetition === 'true';
    }
  }

  get selectedFamilyElement() {
    return this._selectedFamilyElement;
  }

  get codeSubjectToCompetition() {
    return this.subjectToCompetitionCheckBoxTarget.checked;
  }

  set defaultLegalThreshold(float) {
    this._defaultLegalThreshold = float ? float.toFixed(2).replace('.', ',') : '';
  }

  get defaultLegalThreshold() {
    return this._defaultLegalThreshold;
  }

  get currentCrtpDispenseTypeBtn() {
    return this.crtpDispenseTypeRadioBtnTargets.find((elem) => elem.checked);
  }
}
