import { Controller } from '@hotwired/stimulus';
import Cookies from 'js-cookie';
import TurboRequest from '../../turbo_request';

const densityClass = (level) => `roadmap--density-${level}`;

// Connects to data-controller="planner--roadmap"
export default class extends Controller {
  static targets = ['dateline', 'datelineScroller', 'month', 'date', 'dateMarker', 'timelines', 'scroller', 'markets', 'focusedOn'];

  static values = {
    addDatesUrl: String,
  };

  initialize() {
    this.scrollLeft = 0;
    this.scrollTop = 0;

    this.initiateDensity();

    this.previousScrollData = {};

    this.initPhase = true;
  }

  connect() {
    this.setupSyncScroll();
  }

  initiateDensity() {
    const savedDensity = Cookies.get('roadmapDensity');
    if (typeof savedDensity !== 'undefined') {
      const level = parseInt(savedDensity, 10);

      this.density = level;
      this.element.classList.add(densityClass(level));
    } else {
      this.density = 5;
      this.element.classList.add(densityClass(5));
    }
  }

  setupSyncScroll() {
    this.scrollerTarget.addEventListener('scroll', this.scrollHandler.bind(this));
  }

  scrollHandler() {
    const direction = this.scrollerTarget.scrollTop === this.scrollTop ? 'horizontal' : 'vertical';
    this.syncScroll(direction);
  }

  syncScroll(direction) {
    if (direction === 'vertical') {
      this.marketsTarget.scrollTop = this.scrollerTarget.scrollTop;
      this.scrollTop = this.scrollerTarget.scrollTop;
    } else {
      this.datelineTarget.scrollLeft = this.scrollerTarget.scrollLeft;
      this.scrollLeft = this.scrollerTarget.scrollLeft;
    }
  }

  zoomIn(e) {
    e.preventDefault();
    const newDensity = this.density > 0 ? this.density - 1 : 0;
    this.zoomTo(newDensity);
  }

  zoomOut(e) {
    e.preventDefault();
    const newDensity = this.density < 5 ? this.density + 1 : 5;
    this.zoomTo(newDensity);
  }

  zoomTo(level) {
    // Premier datemarker visible avant le resize
    const initialScrollLeft = this.scrollerTarget.scrollLeft;
    const [minScreenX, maxScreenX] = [initialScrollLeft, initialScrollLeft + 35];

    const selector = [];
    for (let i = minScreenX; i < maxScreenX; i += 1) {
      selector.push(`[data-screen-x="${i}"]`);
    }

    const firstDateMarkerVisible = document.querySelector(selector.join(','));

    const newDensityClass = densityClass(level);
    this.element.classList.replace(this.currentDensityClass, newDensityClass);
    this.density = level;

    this.setScreenXOnDateMarkers();

    const newScrollLeft = firstDateMarkerVisible.dataset.screenX;
    this.scrollerTarget.scrollLeft = newScrollLeft;

    this.timelinesTarget.style.width = `${this.datelineWidth}px`;
    this.dispatch('densityChanged');

    Cookies.set('roadmapDensity', level);
  }

  monthTargetConnected() {
    this.setScreenXOnDateMarkers();

    this.timelinesTarget.style.width = `${this.datelineWidth}px`;
    this.dispatch('datesChanged');

    if (Object.keys(this.previousScrollData).length > 0) this.preserveScroll();

    this.setupScrollObserver(false, true);

    if (this.initPhase) {
      console.log("today marker", this.todayMarker)
      if (typeof this.todayMarker != 'undefined') this.scrollToToday();

      if (this.hasFocusedOnTarget) {
        const scrollTop = this.focusedOnTarget.offsetTop;
        this.scrollerTarget.scrollTop = scrollTop;
      }

      this.initPhase = false;
    }
  }

  preserveScroll() {
    const delta = this.scrollerTarget.scrollWidth - this.previousScrollData.previousScrollerWidth;
    const newScrollLeft = delta;
    this.scrollerTarget.scrollLeft = newScrollLeft;
  }

  setupScrollObserver(observeLeftBound, observeRightBound) {
    this.scrollObserver = new IntersectionObserver(
      this.addDates.bind(this),
      {
        root: this.element,
        rootMargin: '0px',
        threshold: [0, 1],
      },
    );

    const observed = [];
    if (observeLeftBound) {
      observed.push(this.leftDateMarkerBound);
    }
    if (observeRightBound) {
      observed.push(this.rightDateMarkerBound);
    }

    observed.forEach((dateMarkerBound) => {
      this.scrollObserver.observe(dateMarkerBound);
    });
  }

  addDates(entries) {
    console.log('add dates', this.initPhase);
    if (this.initPhase) return;

    entries.forEach((entry) => {
      if (entry.target === this.leftDateMarkerBound && entry.intersectionRatio > 0) {
        // console.log('add dates BEFORE');
        // La préservation du scroll n'est utile que pour le cas où on charge des dates dans le passé, pas dans le futur
        this.previousScrollData = {
          previousScrollerWidth: this.scrollerTarget.scrollWidth,
          previousScrollLeft: this.scrollerTarget.scrollLeft,
        };
        this.unobserveDateMarkerBounds(); // Arrête d'observer les bounds en cours

        this.addDatesBefore();
      } else if (entry.target === this.rightDateMarkerBound && entry.intersectionRatio > 0) {
        // console.log('add dates AFTER');
        this.previousScrollData = {};
        this.unobserveDateMarkerBounds(); // Arrête d'observer les bounds en cours

        this.addDatesAfter();
      }
    });
  }

  unobserveDateMarkerBounds() {
    [this.leftDateMarkerBound, this.rightDateMarkerBound].forEach((elem) => {
      this.scrollObserver.unobserve(elem);
    });
  }

  addDatesBefore() {
    const req = new TurboRequest(
      `${this.addDatesUrlValue}?when=before&src_date=${this.firstDate}`,
      {},
      'GET',
    );
    req.call();
  }

  addDatesAfter() {
    const req = new TurboRequest(
      `${this.addDatesUrlValue}?when=after&src_date=${this.lastDate}`,
      {},
      'GET',
    );
    req.call();
  }

  scrollToToday() {
    this.scrollerTarget.scrollLeft = parseFloat(this.todayMarker.dataset.screenX) - 10;
  }

  setScreenXOnDateMarkers() {
    this.dateMarkerTargets.forEach((dateMarkerElement) => {
      dateMarkerElement.dataset.screenX = dateMarkerElement.parentElement.offsetLeft;
    });
  }

  horizontalScroll(e) {
    e.preventDefault();
    this.scrollerTarget.scrollLeft += e.deltaY;
  }

  get todayMarker() {
    return this.dateMarkerTargets.find((el) => el.dataset.today === 'true');
  }

  get datelineWidth() {
    return this.datelineScrollerTarget.offsetWidth;
  }

  get leftDateMarkerBound() {
    return this.dateMarkerTargets[10];
  }

  get rightDateMarkerBound() {
    return this.dateMarkerTargets[this.dateMarkerTargets.length - 10];
  }

  get firstDate() {
    return this.dateMarkerTargets[0].dataset.date;
  }

  get lastDate() {
    return this.dateMarkerTargets[this.dateMarkerTargets.length - 1].dataset.date;
  }

  get currentDensityClass() {
    return densityClass(this.density);
  }
}
