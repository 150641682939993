import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="select-toggler"
export default class extends Controller {
  static targets = ['select', 'content'];

  connect() {
    this.toggle();
  }

  toggle() {
    const { value } = this.selectTarget;

    this.contentTargets.forEach((contentElement) => {
      if (contentElement.dataset.contentFor === value) {
        contentElement.style.display = 'block';

        const checkableBtn = contentElement.querySelector("input[type=radio], input[type=check_box]");
        if (checkableBtn) {
          checkableBtn.checked = true
        }
      } else {
        contentElement.style.display = 'none';
      }
    });
  }
}
