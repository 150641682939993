import { Controller } from '@hotwired/stimulus';
import { getMeta } from '../utils';
// Un form avec turbo-stream incorporé dans un autre form turbo-stream ne peut fonctionner..
// Il faut le faker
export default class extends Controller {
  static targets = [];

  onSubmit(event) {
    event.preventDefault();
    console.log('on submit', event, event.params.toString());
    const form = event.srcElement;
    const { action, method } = form;
    const formData = new FormData(form);

    fetch(action, {
      method,
      headers: {
        'X-CSRF-Token': this.csrfToken,
        Accept: 'text/vnd.turbo-stream.html',
      },
      body: formData,
    }).then((r) => r.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  }

  get csrfToken() {
    return getMeta('csrf-token');
  }
}
