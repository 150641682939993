/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';
import { Dropdown } from 'bootstrap';

const headerCellClass = 'assistant-grid__header-cell';

export default class extends Controller {
  static targets = ['dropdown'];

  connect() {
    this.dropdowns = this.dropdownTargets;
    this.dropdowns.forEach((dropdown) => {
      // Overrides boostrap default config
      const instance = new Dropdown(dropdown, {
        popperConfig(defaultBsPopperConfig) {
          const customConfig = {
            placement: 'right',
          };
          const final = {
            ...defaultBsPopperConfig,
            ...customConfig,
          };
          return final;
        },
      });

      // Add upper z-index on header cell when panel filter is shown
      // so that the panel is not placed under other header cells
      dropdown.addEventListener('show.bs.dropdown', (event) => {
        const btn = event.target;
        const parentHeaderCell = btn.closest(`.${headerCellClass}`);
        parentHeaderCell.classList.add(`${headerCellClass}--filtering`);
      });

      // Force update popper position on shown
      // because it is strangely handled otherwise on smaller screens
      dropdown.addEventListener('shown.bs.dropdown', (event) => {
        const btn = event.target;
        const ddInstance = Dropdown.getInstance(btn);
        // Forced to use setTimeout, otherwise update() is not triggered
        setTimeout(() => {
          ddInstance.update();
        }, 0);
      });

      // Remove upper z-index on hide
      dropdown.addEventListener('hide.bs.dropdown', (event) => {
        const btn = event.target;
        const parentHeaderCell = btn.closest(`.${headerCellClass}`);
        parentHeaderCell.classList.remove(`${headerCellClass}--filtering`);
      });
    });
  }
}
