import { bbox } from '@turf/bbox';

import {
  borderColor, minValueColor, maxValueColor,
  zoomThreshold,
} from './utils';

export default class RegionsLayer {
  constructor(regionCode, map, data, initialZoomLevel) {
    this.regionCode = regionCode;
    this.map = map;
    this.data = data;
    this.initialZoomLevel = initialZoomLevel;

    this.currentView = 'publicMarkets';

    this.sourceName = `dpts-${regionCode}-data`;
    this.fillLayerName = `dpts-${regionCode}-fill`;
    this.outlineLayerName = `dpts-${regionCode}-outline`;

    this.map.addSource(this.sourceName, {
      type: 'geojson',
      data: this.data,
    });
  }

  add() {
    this.addOutlineLayer();
    this.addFillLayer();
  }

  addFillLayer() {
    this.map.addLayer({
      id: this.fillLayerName,
      type: 'fill',
      source: this.sourceName,
      paint: {
        'fill-color': this.buildFillColorExpressionFor('totalCount'),
        'fill-opacity': 0.8,
      },
    }, this.outlineLayerName);
  }

  addOutlineLayer() {
    this.map.addLayer({
      id: this.outlineLayerName,
      type: 'line',
      source: this.sourceName,
      minzoom: zoomThreshold,
      paint: {
        'line-color': borderColor,
        'line-width': 1,
      },
    }, 'regions-fill');
  }

  on(eventName, callback) {
    this.map.on(eventName, this.fillLayerName, (event) => {
      callback(event);
    });
  }

  buildFillColorExpressionFor(coloredProperty) {
    let propertyName;
    let expression;
    let maxValue;

    if (coloredProperty === 'totalCount') {
      propertyName = this.currentView === 'publicMarkets' ? 'publicMarketsCount' : 'providersCount';
      maxValue = this.currentView === 'publicMarkets' ? this.publicMarketsCountMaxValue : this.providersCountMaxValue;
    } else {
      propertyName = this.currentView === 'publicMarkets' ? 'publicMarketsTotalAmount' : 'providersTotalAmount';
      maxValue = this.currentView === 'publicMarkets' ? this.publicMarketsAmountMaxValue : this.providersAmountMaxValue;
    }

    // Il y a un bug si minValue === maxValue
    // Parce que dans setPaintProperty, les limites
    // doivent être strictement dans un ordre ascendant
    if (maxValue === 0) {
      expression = minValueColor;
    } else {
      expression = ['interpolate',
        ['exponential', 1],
        ['get', propertyName], // Utilise la valeur fusionnée
        0, minValueColor,
        maxValue, maxValueColor,
      ];
    }

    return expression;
  }

  changeColoredPropertyTo(coloredProperty) {
    const expression = this.buildFillColorExpressionFor(coloredProperty);

    this.map.setPaintProperty(
      this.fillLayerName,
      'fill-color',
      expression,
    );
  }

  get providersCountMaxValue() {
    return Math.max(...this.data.features.map((f) => f.properties.providersCount));
  }

  get publicMarketsCountMaxValue() {
    return Math.max(...this.data.features.map((f) => f.properties.publicMarketsCount));
  }

  get providersAmountMaxValue() {
    return Math.max(...this.data.features.map((f) => f.properties.providersTotalAmount));
  }

  get publicMarketsAmountMaxValue() {
    return Math.max(...this.data.features.map((f) => f.properties.publicMarketsTotalAmount));
  }

  get bbox() {
    return bbox(this.data);
  }
}
