// Entry point for the build script in your package.json
import '@hotwired/turbo-rails';
import './controllers';

import { Collapse, Dropdown } from 'bootstrap/dist/js/bootstrap.esm';
import { delegate, followCursor } from 'tippy.js';
import { reference } from '@popperjs/core';
import Themis from './modules/themis';
import Notification from './notification';

Themis.start();
window.Notification = Notification;

// Use delegate instead of tyippy to allow newly appended elements to show a tooltip too
// https://atomiks.github.io/tippyjs/v5/addons/
delegate(document.documentElement, {
  target: '[data-tooltip]',
  content: (reference) => reference.dataset.tooltip,
});

delegate(document.documentElement, {
  target: '[data-following-cursor-tooltip]',
  content: (reference) => reference.dataset.followingCursorTooltip,
  followCursor: true,
  plugins: [followCursor],
});

// Adds autoscroll functionnality inside a turbo-frame, after load
const handleFrameLoad = (event) => {
  const src = event.target?.src;
  if (src) {
    const url = new URL(src);
    if (url.searchParams.has('autoscroll_to')) {
      const scrollTargetElement = document.getElementById(url.searchParams.get('autoscroll_to'));
      scrollTargetElement.scrollIntoView(true, { behavior: 'instant' });

      scrollTargetElement.classList.add('tilt-shake');
    }
  }
};

document.documentElement.addEventListener('turbo:frame-load', handleFrameLoad);
