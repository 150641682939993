import { Controller } from '@hotwired/stimulus';
import * as XLSX from 'xlsx';

// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

const EXCEL_CONTENT_TYPES = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

export default class extends Controller {
  static targets = ['selectWraper', 'selectSheet', 'sheetNameInput', 'loader'];

  connect() {
    this.sheetNames = [];
    console.log("connection")
  }

  detectFileType(event) {
    const input = event.target;
    const file = input.files[0];

    this.showLoader();

    if (EXCEL_CONTENT_TYPES.includes(file.type)) {
      this.askForSheetName(file);
    } else {
      this.resetSelect();
      this.selectWraperTarget.setAttribute('hidden', true);
      this.hideLoader();
    }
  }

  pickSheet(e) {
    const target = e.target;
    const pickedSheetName = this.sheetNames[target.value];

    this.sheetNameInputTarget.value = pickedSheetName;
  }

  showLoader() {
    this.loaderTarget.removeAttribute('hidden');
  }

  hideLoader() {
    this.loaderTarget.setAttribute('hidden', true);
  }

  async askForSheetName(file) {
    this.resetSelect();
    this.sheetNames = await this.extractSheetNamesFromFile(file);
    this.sheetNames.forEach((sheetName, index) => {
      const { length } = this.selectSheetTarget.options;
      this.selectSheetTarget.options[length] = new Option(sheetName, index);
    });

    this.selectWraperTarget.removeAttribute('hidden');
    this.hideLoader();
  }

  resetSelect() {
    this.selectSheetTarget.options.length = 0;
  }

  // eslint-disable-next-line class-methods-use-this
  async extractSheetNamesFromFile(file) {
    const data = await file.arrayBuffer();
    return new Promise((resolve) => {
      const readOpts = {
        bookSheets: true,
      };
      /* data is an ArrayBuffer */
      const wb = XLSX.read(data, readOpts);
      resolve(wb.SheetNames);
    });
  }
}
