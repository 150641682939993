import { Controller } from '@hotwired/stimulus';

// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  static targets = [];

  static values = {
    scrollTo: String,
  };

  connect() {
    this.scrollOnSuccess();
    this.targetId = this.scrollToValue;
  }

  scrollOnSuccess() {
    const form = this.element.getElementsByTagName('form')[0];
    form.addEventListener('turbo:submit-end', this.formResponseCallback.bind(this));
  }

  formResponseCallback(event) {
    const submissionDetail = event.detail;
    if (submissionDetail.success) {
      setTimeout(() => {
        document.getElementById(this.targetId).scrollIntoView({
          behavior: 'smooth',
        });
      }, 250);
    }
  }
}
