import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import ApexCharts from 'apexcharts';
import { getCSSColor } from '../../utils';

// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  static targets = ['chart'];

  static values = {
    series: Array,
    frLabels: Array,
    enLabels: Array,
  };

  initialize() {
    this.primaryColor = getCSSColor('primary');
    this.accentColor = getCSSColor('accent1');
    this.lightColor = getCSSColor('accent3');
  }

  connect() {
    this.options = {
      chart: {
        type: 'donut',
        height: 120,
        padding: {
          bottom: -100,
        },
        events: {
          dataPointSelection: (event, chartContext, opts) => {
            Turbo.visit(`/explore?legal_state=${this.enLabelsValue[opts.dataPointIndex]}`);
          },
        },
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 0,
        },
      },
      grid: {
        padding: {
          bottom: -140,
          top: -10,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val, opt) => {
          const index = opt.seriesIndex;
          return this.seriesValue[index].toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
          });
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (val) => val.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
          }),
        },
      },
      colors: [this.primaryColor, this.accentColor, this.lightColor],
      series: this.seriesValue,
      labels: this.frLabelsValue,
    };

    const chart = new ApexCharts(this.chartTarget, this.options);
    chart.render();
  }
}
