import { Controller } from '@hotwired/stimulus';

const badgeHtml = (legalState) => {
  let text;
  let cssClass;
  if (legalState === 'secured') {
    text = 'A aussi du risqué';
    cssClass = 'bg-accent1';
  } else {
    text = 'A aussi du sécurisé';
    cssClass = 'bg-primary';
  }

  return `<span class="badge ${cssClass}">${text}</span>`;
};

export default class extends Controller {
  static targets = ['securedGroups', 'riskyGroups'];

  connect() {
    const securedGroupsNodes = [...this.securedGroupsTarget.querySelectorAll('[data-group-id]')];
    const riskyGroupsNodes = [...this.riskyGroupsTarget.querySelectorAll('[data-group-id]')];

    const concernedNodes = securedGroupsNodes.flatMap((securedNode) => {
      const riskies = riskyGroupsNodes.filter((riskyNode) => riskyNode.dataset.groupId === securedNode.dataset.groupId);
      // console.log('riskies', riskies);
      if (riskies.length) {
        return [securedNode, riskies];
      }
      return [];
    }).flat();

    // console.log('concernednodes', concernedNodes);
    concernedNodes.forEach((node) => {
      const legalState = node.classList.contains('js-secured') ? 'secured' : 'risky';
      node.innerHTML += badgeHtml(legalState);
    });
  }
}
