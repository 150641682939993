/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs/modular/sortable.core.esm.js';
import TurboRequest from '../../../turbo_request';
import { draggedElementStore } from '../../../utils';

export default class extends Controller {
  static targets = ['sortable'];

  static values = {
    sortableObjectType: String,
    sortableListId: Number,
    maxFamilyPosition: Number,
  };

  connect() {
    this.unChooseHandler = this.onUnchoose.bind(this);
    this.chooseHandler = this.onChoose.bind(this);
    // this.sortHandler = this.onSort.bind(this);
    this.dragEndHandler = this.onDragEnd.bind(this);

    this.sortableGroups = this.sortableTargets.reduce((group, sortable) => {
      const { sortableGroupName } = sortable.dataset;
      group[sortableGroupName] = group[sortableGroupName] ?? [];
      group[sortableGroupName].push(sortable);
      return group;
    }, {});

    Object.keys(this.sortableGroups)
      .forEach((groupName) => this.buildSortables(groupName));

    // Ti prevent sortableTargetConnected to fire on connect
    this.connected = true;
  }

  // Lorsqu'on supprime un code, on re-render toute la famille du code
  // Il faut alors reconstruire les instances de Sortable pour donner la possibilité
  // de déplacer les éléments de la famille
  // pour ça, on ajoute l'élément sortable qui vietn de se reconnecter
  // à son groupe de sort et on reconstruit l'instance SOrtable de ce groupe
  sortableTargetConnected(element) {
    if (this.connected !== true) return;
    const { sortableGroupName } = element.dataset;
    this.sortableGroups[sortableGroupName].push(element);
    this.buildSortables(sortableGroupName);
  }

  buildSortables(sortableGroupName) {
    const sortables = this.sortableGroups[sortableGroupName];

    sortables.forEach((sortable) => {
      Sortable.create(sortable, {
        group: sortableGroupName,
        animation: 150,
        fallbackOnBody: true,
        swapThreshold: 0.65,
        handle: '.drag-handle',
        dragoverBubble: true,
        onChoose: this.chooseHandler,
        onUnchoose: this.unChooseHandler,
        onEnd: this.dragEndHandler,
        // onSort: this.sortHandler,
      });
    });
  }

  onDragEnd(event) {
    if (this.sortableObjectTypeValue === 'nomenclature_code') {
      this.handleCodeMove(event);
    } else {
      this.handleFamilyMove(event);
    }
  }

  onUnchoose(event) {
    draggedElementStore.draggedElement = event.item;
    this.sortableTargets.filter((elem) => elem.classList.contains('prevent-drop'))
      .forEach((elem) => { elem.classList.remove('prevent-drop'); });
  }

  onChoose(event) {
    draggedElementStore.draggedElement = event.item;
    const { sortableGroupMember } = event.item.dataset;

    this.sortableTargets
      .filter((sortable) => sortable.dataset.sortableGroupName !== sortableGroupMember)
      .forEach((sortable) => {
        sortable.classList.add('prevent-drop');
      });
    // Prevent dropping on other group name list
    // If groupName is "furnitures" then prevent from dropping inside a group called "services"
  }

  handleFamilyMove(event) {
    const position = event.newIndex;

    const params = {
      nomenclature_family: {
        position,
      },
    };
    event.preventDefault();

    const url = event.item.dataset.moveUrl;
    const request = new TurboRequest(
      url,
      params,
      'PUT',
    );

    request.call();
  }

  handleCodeMove(event) {
    const { codeId } = event.item.dataset;
    console.log("dataset", event.to.dataset)
    const newFamilyId = event.to.dataset["assistant-Mix-SortSortableListIdValue"];
    const position = event.newIndex;

    const params = {
      nomenclature_code: {
        nomenclature_code_id: codeId,
        new_family_id: newFamilyId,
        position,
      },
    };

    const url = event.item.dataset.moveUrl;
    const request = new TurboRequest(
      url,
      params,
      'PUT',
    );

    request.call();
  }
}
