import { Controller } from '@hotwired/stimulus';
import createNotificationSubscription from '../channels/notifications_channel';
import { getCurrentUserId } from '../utils';

export default class extends Controller {
  static targets = [];

  connect() {
    // alert('ok')
    this.userId = getCurrentUserId();
    this.subscription = createNotificationSubscription(this.userId);
    console.log('Notification Subscription connections', this.userId);
  }

  disconnect() {
    // alert("unsubscribing")
    this.subscription.unsubscribe();
  }
}
