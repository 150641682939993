/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';
import ConfirmDialog from '../../../confirm_dialog';
import TurboRequest from '../../../turbo_request';

const activeClass = 'js-active';

export default class extends Controller {
  static targets = ['sourceNomenclatureCode', 'targetNomenclatureCode', 'targetNomenclatureCodeLinkBtn'];

  static values = {
    codesCorrespondenceUrl: String,
  };

  connect() {
    this.selectedSourceNomenclatureCodeElement = null;
    this.selectedTargetNomenclatureCodeElement = null;
  }

  disconnect() {
  }

  onConfirm() {
    const body = {
      nomenclature_code_correspondence: {
        source_nomenclature_code_id: this.selectedSourceNomenclatureCodeElement.dataset.codeId,
        target_nomenclature_code_id: this.selectedTargetNomenclatureCodeElement.dataset.codeId,
      },
    };

    const request = new TurboRequest(
      this.codesCorrespondenceUrlValue,
      body,
    );

    request.call();
  }

  onCancelConfirmation() {
    this.selectedTargetNomenclatureCodeElement = null;
  }

  toggle(event) {
    event.preventDefault();
    event.stopPropagation();

    const { codeId, codeType } = event.params;
    const list = codeType === 'targetNomenclatureCode' ? this.targetNomenclatureCodeTargets : this.sourceNomenclatureCodeTargets;
    const codeElement = list.find((code) => code.dataset.codeId == codeId);

    if (codeElement) {
      if (codeType === 'targetNomenclatureCode') {
        if (codeElement === this.selectedTargetNomenclatureCodeElement) {
          this.selectedTargetNomenclatureCodeElement = null;
        } else {
          this.selectedTargetNomenclatureCodeElement = codeElement;
        }
      } else if (codeElement === this.selectedSourceNomenclatureCodeElement) {
        this.selectedSourceNomenclatureCodeElement = null;
        this.selectedTargetNomenclatureCodeElement = null;
      } else {
        this.selectedSourceNomenclatureCodeElement = codeElement;
        this.selectedTargetNomenclatureCodeElement = null;
      }
    }

    if (codeType === 'targetNomenclatureCode' && this.bothCodesSelected) {
      this.showConfirm();
    }
  }

  showConfirm() {
    const sourceCodeLabel = this.selectedSourceNomenclatureCodeElement.dataset.codeLabel;
    const targetCodeLabel = this.selectedTargetNomenclatureCodeElement.dataset.codeLabel;
    const confirm = new ConfirmDialog('confirm-codes-correspondence');

    confirm.on('confirm', () => this.onConfirm());
    confirm.on('cancel', () => this.onCancelConfirmation());

    confirm.question = `<span class="mb-2 d-inline-block">Voulez-vous faire correspondre le code <strong>"${sourceCodeLabel}"</strong> au code <strong>"${targetCodeLabel}"</strong> ?</span>
      <br />
      <small class="text-muted">
        Le code "${targetCodeLabel}" sera assigné à tous les mandats non-vérrouillés qui ont actuellement le code "${sourceCodeLabel}"
      </small>`;

    confirm.show();
  }

  get bothCodesSelected() {
    return this.selectedSourceNomenclatureCodeElement !== null && this.selectedTargetNomenclatureCodeElement !== null;
  }

  set selectedTargetNomenclatureCodeElement(element) {
    this._selectedTargetNomenclatureCodeElement = element;
    if (element !== null) {
      this.targetNomenclatureCodeTargets.forEach((el) => {
        el.classList.remove(activeClass);
      });
      element.classList.add(activeClass);
    } else {
      this.targetNomenclatureCodeTargets.forEach((el) => {
        el.classList.remove(activeClass);
      });
    }
  }

  get selectedTargetNomenclatureCodeElement() {
    return this._selectedTargetNomenclatureCodeElement;
  }

  set selectedSourceNomenclatureCodeElement(element) {
    this._selectedSourceNomenclatureCodeElement = element;

    if (element) {
      this.sourceNomenclatureCodeTargets.forEach((el) => {
        el.classList.remove(activeClass);
      });
      this.targetNomenclatureCodeLinkBtnTargets.forEach((btn) => {
        btn.classList.remove('disabled');
      });
      element.classList.add(activeClass);
    } else {
      this.sourceNomenclatureCodeTargets.forEach((el) => {
        el.classList.remove(activeClass);
      });
      this.targetNomenclatureCodeLinkBtnTargets.forEach((btn) => {
        btn.classList.add('disabled');
      });
    }
  }

  get selectedSourceNomenclatureCodeElement() {
    return this._selectedSourceNomenclatureCodeElement;
  }
}
