import { Controller } from '@hotwired/stimulus';
import StepResizer from '../../modules/planner/step_resizer';
import StepDragger from '../../modules/planner/step_dragger';
import { dateFromLocaleString } from '../../utils';

// Connects to data-controller="planner--roadmap-step"
export default class extends Controller {
  static values = {
    startOn: String,
    endOn: String,
    duration: Number,
    durationMin: Number,
    durationMax: Number,
    id: Number
  };

  connect() {
    setTimeout(() => {
      this.positionHorizontally();

      this.resizer = new StepResizer(this.element);
      this.element.addEventListener('step-resizer:resized', this.changed.bind(this));

      this.dragger = new StepDragger(this.element);
      this.element.addEventListener('step-dragger:dragged', this.changed.bind(this));
    }, 0);
  }

  changed(event) {
    this.changeDates(event.detail)
  }

  highlightDates() {
    if (this.element.classList.contains('roadmap-step--resizing') || this.element.classList.contains('roadmap-step--dragging')) return;
    const startDate = dateFromLocaleString(this.startOnValue);
    const endDate = dateFromLocaleString(this.endOnValue);
    // const dateMarkersBetween = getElementsBetween(startOnDateMarkerEkement.parentElement, endOnDateMarkerEkement.parentElement);
    // console.log("dateMarkersBetween", dateMarkersBetween)
    // dateMarkersBetween.forEach((dateMarkerParent) => {
    //   dateMarkerParent.classList.add('roadmap__date--highlighted')
    // })

    // loop for every day
    const datesToHighlight = []
    for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
      // your day is here
      const cssSelector = `.roadmap__date[data-date="${day.toLocaleDateString()}"]`;
      datesToHighlight.push(cssSelector)
    }

    Array.from(document.querySelectorAll(datesToHighlight.join(','))).forEach((dateElement, index) => {
      if (index === 0) {
        dateElement.classList.add('roadmap__date--border-left');
      } else if (index === datesToHighlight.length - 1) {
        dateElement.classList.add('roadmap__date--border-right');
      }
      dateElement.classList.add('roadmap__date--highlighted')
    })
  }

  removeDatesHighlight() {
    Array.from(document.querySelectorAll('.roadmap__date--highlighted')).forEach((date) => {
      date.classList.remove('roadmap__date--highlighted', 'roadmap__date--border-left', 'roadmap__date--border-right');
    })
  }

  changeDates(detail) {
    this.startOnValue = detail.startOn.toLocaleDateString();
    this.endOnValue = detail.endOn.toLocaleDateString();
    if ('duration' in detail) {
      this.durationValue = detail.duration;
    }
    
    // est écouté dans timeline_controller pour bulk_update
    this.dispatch("changed");
  }

  // update() {
  //   const body = {
  //     procurement_process_step: {
  //       start_on: this.startOnValue,
  //       end_on: this.endOnValue,
  //       duration: this.durationValue,
  //     },
  //   };
  //   const request = new TurboRequest(this.updateUrlValue, body, 'PATCH');
  //   request.call();
  // }

  positionHorizontally() {
    if (this.element.style.position === 'fixed') return;
    const newX = this.offsetLeft;
    this.element.style.transform = `translateX(${newX}px)`;
    this.element.dataset.x = newX;
    this.element.style.width = `${this.endOnOffset - this.offsetLeft}px`;
  }

  get offsetLeft() {
    return document.querySelector(`[data-date="${this.startOnValue}"]`).offsetLeft + 2;
  }

  // La date de fin est inclue
  get endOnOffset() {
    const element = document.querySelector(`[data-date="${this.endOnValue}"]`);
    return element.offsetLeft + element.offsetWidth - 4;
  }
}
