/* eslint-disable no-new */
/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';
import { useResize } from 'stimulus-use';

const minFontSize = 0.6;
const maxFontSize = 0.95;
const baseLineHeight = 1.5;

export default class extends Controller {
  static values = {
    familyCount: Number,
  };

  initialize() {
    this.setFontSize();
  }

  connect() {
    useResize(this);
  }

  resize() {
    this.setFontSize();
  }

  setFontSize() {
    const containerHeight = this.element.offsetHeight;
    const fontSize = containerHeight / (this.familyCountValue * baseLineHeight);
    const fontSizePx = `${fontSize}px`;

    this.element.style.fontSize = `min(max(${fontSizePx},${minFontSize}rem), ${maxFontSize}rem)`;
  }
}
