/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';
import assistantSelectedMandatesStorageKey from './utils';

export default class extends Controller {
  static targets = ['selectionCounter', 'mandateIdsInput'];

  connect() {
    this.selectedIds = JSON.parse(sessionStorage.getItem(assistantSelectedMandatesStorageKey));
    this.selectedIdsCount = this.selectedIds.length;

    this.mandateIdsInputName = this.mandateIdsInputTarget.getAttribute('name');

    this.populateMandateIdsInput();
  }

  populateMandateIdsInput() {
    this.mandateIdsInputTarget.value = this.selectedIds.join(',');
  }

  addMandateIdsToForm() {
    this.mandateIdsInputTarget.setAttribute('name', this.mandateIdsInputName);
  }

  // Removing name attribute make the form ignore the field
  removeMandateIdsFromForm() {
    this.mandateIdsInputTarget.setAttribute('name', '');
  }

  set selectedIdsCount(count) {
    this._selectedIdsCount = count;
    this.selectionCounterTargets.forEach((el) => {
      el.innerText = count;
    });
  }
}
