/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';
import * as XLSX from 'xlsx';

export default class extends Controller {
  static targets = [];

  static values = {
    url: String,
  };

  connect() {
    console.log('connecting', this.urlValue);
  }

  export(event) {
    event.preventDefault();

    fetch(
      `${this.urlValue}`,
      {
        headers: {
          Accept: 'text/csv',
        },
      },
    )
      .then((response) => response.text())
      .then((response) => {
        const wb = XLSX.read(response, {
          type: 'string', raw: false, dense: true, cellStyles: true,
        });
        XLSX.writeFile(wb, 'Export.xlsx', { bookSST: true, cellStyles: true });
      });
  }
}
