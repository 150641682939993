import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import ApexCharts from 'apexcharts';
import merge from 'lodash.merge';
import {
  getCSSColor,
  euroFormatter,
  defaultFormatter,
} from '../../utils';

// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  static targets = ['chart'];

  static values = {
    series: Array,
    printed: {
      type: Boolean,
      default: false,
    },
    frLabels: Array,
    enLabels: Array,
    dataLabelsType: String,
    unit: { type: String, default: 'euro' },
    clickable: { type: Boolean, default: true },
    clickUrls: { type: Array, default: [] },
    legendPosition: { type: String, default: 'right' },
    colors: Array,
  };

  initialize() {
    console.log('pie chart 2');
    this.primaryColor = getCSSColor('primary');
    this.accentColor = getCSSColor('accent1');
    this.lightColor = getCSSColor('accent3');

    this.finalColors = this.hasColorsValue
      ? this.colorsValue.map((name) => getCSSColor(name))
      : [this.primaryColor, this.accentColor, this.lightColor];

    this.idealHeight = this.chartTarget.parentElement.clientHeight;
  }

  connect() {
    this.formatter = this.unitValue === 'euro' ? euroFormatter : defaultFormatter;

    this.chart = new ApexCharts(this.chartTarget, this.options);
    this.chart.render();
  }

  get options() {
    const opts = {
      chart: {
        type: 'donut',
        height: 200,
        events: {
          dataPointSelection: (event, chartContext, opts) => {
            if (this.clickableValue) {
              if (this.clickUrlsValue.length > 0) {
                const url = this.clickUrlsValue[opts.dataPointIndex];
                window.scrollTo(0, 0); // Obligé d'ajouter ça sinon le scroll est préservé
                Turbo.visit(url);
              } else {
                window.scrollTo(0, 0); // Obligé d'ajouter ça sinon le scroll est préservé
                Turbo.visit(`/explore?legal_state=${this.enLabelsValue[opts.dataPointIndex]}`);
              }
            }
          },
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (val) => this.formatter(val),
        },
      },
      colors: this.finalColors,
      series: this.seriesValue,
      labels: this.frLabelsValue,
      grid: {
        padding: {
          bottom: -10,
          top: -8,
        },
      },
      legend: {
        position: this.legendPositionValue,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                offsetY: -5,
                fontSize: 15,
                formatter: (val) => {
                  const floatVal = parseFloat(val);
                  return this.formatter(floatVal);
                },
              },
              total: {
                show: true,
                color: '#000',
                fontSize: 15,
                formatter: (w) => {
                  const sum = w.config.series.reduce((a, b) => a + b);
                  return this.formatter(sum);
                },
              },
            },
          },
        },
      },
    };

    if (this.printedValue) {
      return merge(opts, this.printedOptions);
    }
    return opts;
  }

  removeSerie(event) {
    event.preventDefault();
    const serieName = event.params.serie;
    const serieIndex = this.frLabelsValue.indexOf(serieName);
    // Pour ne pas mutate seriesValue, on utilise filter
    const newSeries = this.seriesValue.filter((value, index) => index !== serieIndex);
    this.chart.updateSeries(newSeries);
  }

  addSerie(event) {
    event.preventDefault();
    this.chart.updateSeries(this.seriesValue);
  }

  get printedOptions() {
    return {
      chart: {
        toolbar: {
          show: false,
        },
      },
    };
  }

  dataLabelsFormatter(val, opts) {
    if (this.hasDataLabelsTypeValue && this.dataLabelsTypeValue === 'series') {
      const index = opts.seriesIndex;
      return this.seriesValue[index].toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      });
    }
    return `${val}%`;
  }
}
