import {
  getPreviousSiblings,
  getNextSiblings,
  areIntersecting,
  addDaysToDate,
  substractDaysToDate,
} from './step_drag_resize_utils';

import { dateFromLocaleString } from '../../utils';

export default class CollisionHandler {
  constructor(element) {
    this.element = element;

    this.dateMarkerWidth = document.querySelector('.roadmap__date').offsetWidth;

    this.previousSiblings = getPreviousSiblings(this.element, this.element.parentElement);
    this.nextSiblings = getNextSiblings(this.element, this.element.parentElement);
  }

  checkAndHandleCollision(onLeftEdge = true, onRightEdge = true) {
    if (onRightEdge && this.isRightEdgeOverlappingSibling()) {
      this.nextSiblings.forEach((sibling) => {
        this.applyChangeTo(sibling, '+');
      });
    } else if (onLeftEdge && this.isLeftEdgeOverlappingSibling()) {
      this.previousSiblings.forEach((sibling) => {
        this.applyChangeTo(sibling, '-');
      });
    }
  }

  applyChangeTo(sibling, operand) {
    const initialX = parseFloat(sibling.dataset.x);
    const siblingStartOn = dateFromLocaleString(sibling.dataset["planner-RoadmapStepStartOnValue"]);
    const siblingEndOn = dateFromLocaleString(sibling.dataset["planner-RoadmapStepEndOnValue"]);

    const newX = operand === '+'
      ? initialX + this.dateMarkerWidth
      : initialX - this.dateMarkerWidth;
    const newStartOn = operand === '+'
      ? addDaysToDate(siblingStartOn, 1)
      : substractDaysToDate(siblingStartOn, 1);
    const newEndOn = operand === '+'
      ? addDaysToDate(siblingEndOn, 1)
      : substractDaysToDate(siblingEndOn, 1);

    Object.assign(sibling.style, {
      transform: `translateX(${newX}px)`,
    });

    Object.assign(sibling.dataset, {
      x: newX,
      'planner-RoadmapStepStartOnValue': newStartOn.toLocaleDateString(),
      'planner-RoadmapStepEndOnValue': newEndOn.toLocaleDateString(),
    });
  }

  isRightEdgeOverlappingSibling() {
    if (this.nextSiblings.length === 0) return false;
    const elemRect = this.element.getBoundingClientRect();
    const firstNextSibling = this.nextSiblings[0].getBoundingClientRect();

    // On veut savoir si les éléments ont une intersection par la droite de this.element
    const elemRightEdge = {
      left: elemRect.right - 15,
      right: elemRect.right - 14, // Avec une sécurité de 15px
    };

    return areIntersecting(elemRightEdge, firstNextSibling);
  }

  isLeftEdgeOverlappingSibling() {
    if (this.previousSiblings.length === 0) return false;
    const elemRect = this.element.getBoundingClientRect();
    const firstPreviousSibling = this.previousSiblings[this.previousSiblings.length - 1].getBoundingClientRect();

    // On veut savoir si les éléments ont une intersection par la gauche de this.element
    const elemLeftEdge = {
      left: elemRect.left + 7,
      right: elemRect.left + 8,
    };

    return areIntersecting(elemLeftEdge, firstPreviousSibling);
  }
}
