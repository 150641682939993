import { Controller } from '@hotwired/stimulus';
// Connects to data-controller="assistant--filter-panel"
export default class extends Controller {
  static targets = ['form', 'checkAllToggler', 'resetBtn', 'fieldToReset'];

  static values = {
    filterName: String,
  };

  connect() {
    this.formDataHandler = this.handleFormData.bind(this);
    this.filterName = `filters[${this.filterNameValue}][]`;
    this.formTarget.addEventListener('formdata', this.formDataHandler);
  }

  handleFormData(event) {
    if (this.hasCheckAllTogglerTarget) {
      if (this.allChecked || this.allUnchecked || this.resetForm) {
        event.formData.set(this.filterName, 'all');
      }
    }

    if (this.resetForm && this.hasFieldToResetTarget) {
      this.fieldToResetTargets.forEach((field) => {
        event.formData.set(field.getAttribute('name'), '');
      });
    }

    this.resetForm = false;
  }

  reset(event) {
    event.preventDefault();
    this.resetForm = true;
    this.formTarget.requestSubmit();
  }

  get allChecked() {
    const { filteredResults } = this.checkAllTogglerTarget.dataset;
    return this.checkAllTogglerTarget.checked
      && !this.checkAllTogglerTarget.indeterminate
      && filteredResults === 'false';
  }

  get allUnchecked() {
    return !this.checkAllTogglerTarget.checked && !this.checkAllTogglerTarget.indeterminate;
  }
}
