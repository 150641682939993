import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import ApexCharts from 'apexcharts';
import {
  getCSSColor,
  buildChartClickDestination,
  euroFormatter,
} from '../../utils';
// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  static targets = ['chart'];

  static values = {
    series: Array,
    frLabels: Array,
    groupedBy: String,
    color: String,
    currentLegalState: String,
    barClickable: {
      type: Boolean,
      default: false,
    },
    xAxisLabelClickable: {
      type: Boolean,
      default: true,
    },
  };

  initialize() {
    this.color = getCSSColor(this.colorValue);

    const idealChartHeight = (this.seriesValue.length + 1) * 30;
    const minChartHeight = 100;
    this.idealChartHeight = idealChartHeight < minChartHeight ? minChartHeight : idealChartHeight;
  }

  connect() {
    this.options = {
      chart: {
        type: 'bar',
        height: this.idealChartHeight,
        events: {
          dataPointSelection: (event, chartContext, opts) => {
            if (this.barClickableValue) {
              const destination = buildChartClickDestination(
                `${this.groupedByValue}/${this.seriesValue[opts.dataPointIndex].x[0]}`,
                true,
                this.hasCurrentLegalStateValue ? this.currentLegalStateValue : 'all',
              );
              window.scrollTo(0, 0); // Obligé d'ajouter ça sinon le scroll est préservé
              Turbo.visit(destination);
            }
          },
          xAxisLabelClick: (event, chartContext, opts) => {
            if (this.xAxisLabelClickableValue) {
              const destination = buildChartClickDestination(
                `${this.groupedByValue}/${this.seriesValue[opts.labelIndex].x[0]}`,
                true,
                'all',
              );
              window.scrollTo(0, 0); // Obligé d'ajouter ça sinon le scroll est préservé
              Turbo.visit(destination);
            }
          },
          mounted: (ctx, conf) => {
            if (this.xAxisLabelClickableValue && typeof conf.config.chart.events.xAxisLabelClick === 'function') {
              // Si graphique en barre, alors en fait c'est Y et pas X qui est cliquable
              let axisCssClass;
              if (conf.config.chart.type === 'bar') {
                axisCssClass = '.apexcharts-yaxis-label';
              } else {
                axisCssClass = '.apexcharts-xaxis-label';
              }

              const labels = [...document.querySelectorAll(axisCssClass)];
              labels.forEach((label) => {
                label.classList.add('cursor-pointer');
              });
            }
          },
        },
      },
      tooltip: {
        y: {
          formatter: (val) => euroFormatter(val),
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#333'],
        },
        offsetX: 35,
        offsetY: 7,
        formatter: (val) => euroFormatter(val),
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '70%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      xaxis: {
        tickPlacement: 'on',
      },
      yaxis: {
        type: 'category',
        seriesName: 'Code nomenclature',
        labels: {
          formatter: (val) => (val.length === 3 ? `${val[1]} - ${val[2]}` : val[1]),
        },
      },
      series: [{
        name: 'Montant mandaté',
        data: this.seriesValue,
      }],
      colors: this.color,
      fill: {
        backgroundColor: this.primaryColor,
      },
    };

    const chart = new ApexCharts(this.chartTarget, this.options);
    chart.render();
  }
}
