/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { getMeta } from '../../../utils';

const dataTransferType = 'jsonObj';

export default class extends Controller {
  static values = {
    codeId: Number,
    connectionType: String,
    createConnectionUrl: String,
  };

  initialize() {
    this.dragEnterHandler = this.onDragenter.bind(this);
    this.dragOverHandler = this.onDragover.bind(this);
    this.dragLeaveHandler = this.onDragleave.bind(this);
    this.dropHandler = this.onDrop.bind(this);

    this.nomenclatureId ||= getMeta('nomenclature-id');
    this.csrfParam ||= getMeta('csrf-param');
    this.csrfToken ||= getMeta('csrf-token');

    this.codeElement = this.element.closest('.mix-screen-code');
  }

  connect() {
    this.element.addEventListener('dragenter', this.dragEnterHandler);
    this.element.addEventListener('dragover', this.dragOverHandler);
    this.element.addEventListener('dragleave', this.dragLeaveHandler);
    this.element.addEventListener('drop', this.dropHandler);
  }

  // If the item can be dropped on the code we're in,
  // then preventDefault() to allow the drop
  onDragover(event) {
    if (this.isDropAllowed) {
      event.preventDefault();
    }
  }

  onDragenter(event) {
    event.preventDefault();
    if (!this.isDropAllowed) return;
    this.element.classList.add('mix-screen-code__connected-items--draggedover');
  }

  onDragleave() {
    this.element.classList.remove('mix-screen-code__connected-items--draggedover');
  }

  onDrop(event) {
    if (!this.isDropAllowed) return;

    const parsedData = JSON.parse(event.dataTransfer.getData(dataTransferType));
    const { operationType } = parsedData;

    if (operationType === 'swapConnection') {
      const { updateUrl, codeId } = parsedData.data;
      this.update(updateUrl, codeId);
    } else if (operationType === 'createConnection') {
      const { itemId } = parsedData.data;
      this.createConnection(itemId);
    }

    this.element.classList.remove('mix-screen-code__connected-items--draggedover');
  }

  createConnection(itemId) {
    const formData = new FormData();
    formData.append('nomenclature_code_item[nomenclature_code_id]', this.codeIdValue);
    formData.append('nomenclature_code_item[nomenclature_item_id]', itemId);
    formData.append('nomenclature_code_item[inclusion]', this.connectionTypeValue === 'inclusion');
    formData.append(this.csrfParam, this.csrfToken);

    fetch(this.createConnectionUrlValue, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    }).then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }

  update(updateUrl, codeId) {
    const formData = new FormData();
    formData.append('initial_code_id', codeId);
    formData.append('new_code_id', this.codeIdValue);
    formData.append('inclusion', this.connectionTypeValue === 'inclusion');
    formData.append(this.csrfParam, this.csrfToken);

    fetch(updateUrl, {
      method: 'PATCH',
      body: formData,
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    }).then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }

  get isDropAllowed() {
    // return this.codeElement.classList.contains('mix-screen-code--droppable');
    return true;
  }
}
