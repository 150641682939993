import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import ApexCharts from 'apexcharts';
import merge from 'lodash.merge';
import {
  getCSSColor,
  buildChartClickDestination,
} from '../../utils';

const switchStackFillBtns = `
  <div class="text-center w-100 mt-3 mb-2">
    <div class="btn-group btn-group-sm">
      <input type="radio" class="btn-check" data-action="charts--stacked-bar-chart#toggleStackFill" name="stack-fill-options" id="stack-fill-option1" autocomplete="off" checked>
      <label class="btn btn-outline-light" for="stack-fill-option1">Barres proportionnelles</label>

      <input type="radio" class="btn-check" data-action="charts--stacked-bar-chart#toggleStackFill"  name="stack-fill-options" id="stack-fill-option2" autocomplete="off">
      <label class="btn btn-outline-light" for="stack-fill-option2">Barres entières</label>
    </div>
  </div>
`;

// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  static targets = ['chart'];

  static values = {
    series: Array,
    categories: Array,
    groupedBy: String,
    printed: {
      type: Boolean,
      default: false,
    },
    stackFill: {
      type: Boolean,
      default: false,
    },
    barClickable: {
      type: Boolean,
      default: false,
    },
    xAxisLabelClickable: {
      type: Boolean,
      default: true,
    },
    colors: {
      type: String,
      default: 'default',
    }, // Can be: default or gradient
  };

  initialize() {
    this.primaryColor = getCSSColor('primary');
    this.accentColor = getCSSColor('accent1');
    this.lightColor = getCSSColor('gray-500');

    this.stackFill = this.stackFillValue;

    const idealChartHeight = (this.categoriesValue.length + 1) * 40;
    const minChartHeight = 100;
    this.idealChartHeight = idealChartHeight < minChartHeight ? minChartHeight : idealChartHeight;
    // this.element.parentNode.style.minHeight = `${this.idealChartHeight + 150}px`;
  }

  toggleStackFill(e) {
    e.preventDefault();
    if (this.stackFill === true) {
      this.stackFill = false;
    } else {
      this.stackFill = true;
    }
  }

  connect() {
    this.renderChart();
    this.prependSwitchStackFillBtns();
  }

  prependSwitchStackFillBtns() {
    this.chartTarget.insertAdjacentHTML('beforebegin', switchStackFillBtns);
  }

  renderChart() {
    console.log('render chart options', this.options);
    this.chart = new ApexCharts(this.chartTarget, this.options);
    this.chart.render();
  }

  get options() {
    const opts = {
      chart: {
        type: 'bar',
        stacked: true,
        height: this.idealChartHeight,
        stackType: this.stackFill ? '100%' : 'normal',
        events: {
          dataPointSelection: (event, chartContext, opts) => {
            if (this.barClickableValue) {
              const destination = buildChartClickDestination(
                `${this.groupedByValue}/${this.categoriesValue[opts.dataPointIndex][0]}`,
                true,
                opts.seriesIndex === 0 ? 'risky' : 'secured',
              );
              window.scrollTo(0, 0); // Obligé d'ajouter ça sinon le scroll est préservé
              Turbo.visit(destination);
            }
          },
          xAxisLabelClick: (event, chartContext, opts) => {
            if (this.xAxisLabelClickableValue) {
              const destination = buildChartClickDestination(
                `${this.groupedByValue}/${this.categoriesValue[opts.labelIndex][0]}`,
                true,
                'all',
              );
              window.scrollTo(0, 0); // Obligé d'ajouter ça sinon le scroll est préservé
              Turbo.visit(destination);
            }
          },
          mounted: (ctx, conf) => {
            if (this.xAxisLabelClickableValue && typeof conf.config.chart.events.xAxisLabelClick === 'function') {
              // Si graphique en barre, alors en fait c'est Y et pas X qui est cliquable
              let axisCssClass;
              if (conf.config.chart.type === 'bar') {
                axisCssClass = '.apexcharts-yaxis-label';
              } else {
                axisCssClass = '.apexcharts-xaxis-label';
              }

              const labels = [...document.querySelectorAll(axisCssClass)];
              labels.forEach((label) => {
                label.classList.add('cursor-pointer');
              });
            }
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val, opts) => {
          const newVal = opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex];
          return newVal === 0 ? '' : newVal.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
          });
        },
        offsetY: 6,
      },
      legend: {
        show: false,
        offsetY: 10,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '70%',
          dataLabels: {
            position: 'center',
            hideOverflowingLabels: true,
          },
        },
      },
      tooltip: {
        y: {
          formatter: (val) => val.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
          }),
        },
      },
      yaxis: {
        tickPlacement: 'on',
        // categories: this.categoriesValue,
        labels: {
          formatter: (val) => (val.length === 3 ? `${val[1]} - ${val[2]}` : val[1]),
        },
      },
      xaxis: {
        type: 'category',
        categories: this.categoriesValue,
        labels: {
          formatter: (val) => val.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
          }),
        },
      },
      series: this.seriesValue,
      colors: [this.accentColor,
        this.primaryColor,
        this.lightColor,
      ],
      fill: {
        backgroundColor: this.primaryColor,
      },
    };

    if (this.printedValue) {
      return merge(opts, this.printedOptions);
    }
    return opts;
  }

  get printedOptions() {
    return {
      chart: {
        toolbar: {
          show: false,
        },
      },
    };
  }

  set stackFill(val) {
    this._stackFill = val;

    if (typeof this.chart === 'undefined') return;

    this.chartTarget.style.height = this.chartTarget.style.minHeight;
    this.chart.destroy();
    this.renderChart();
  }

  get stackFill() {
    return this._stackFill;
  }
}
