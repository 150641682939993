import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import ApexCharts from 'apexcharts';
import merge from 'lodash.merge';
import {
  getCSSColor,
  getColorsFromLegalStates,
  euroFormatter,
} from '../../utils';

// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  static targets = ['chart'];

  static values = {
    series: Array,
    printed: {
      type: Boolean,
      default: false,
    },
    categories: Array,
    groupedBy: String,
    filterBy: String,
    filterId: Number,
    clickable: { type: Boolean, default: true },
  };

  initialize() {
    this.primaryColor = getCSSColor('primary');
    this.accentColor = getCSSColor('accent1');
    this.lightColor = getCSSColor('gray-500');
  }

  connect() {
    const chart = new ApexCharts(this.chartTarget, this.options);
    chart.render();
  }

  get options() {
    const opts = {
      chart: {
        type: 'treemap',
        height: '380px',
        offsetX: 10,
        events: {
          dataPointSelection: (event, chartContext, opts) => {
            if (this.clickableValue) {
              const { seriesIndex, dataPointIndex } = opts;
              const groupId = opts.w.config.series[seriesIndex].data[dataPointIndex].id;
              const searchObj = {};
              searchObj[`${this.filterByValue}`] = this.filterIdValue;
              searchObj.group_by = this.groupedByValue;
              searchObj.group_id = groupId;
              searchObj.legal_state = seriesIndex === 0 ? 'risky' : 'secured';

              const searchParams = new URLSearchParams(searchObj);
              const url = `/explore/side_panels/mandates_per_legal_state_table?${searchParams.toString()}#secured-group-${groupId}`;

              Turbo.visit(url, { frame: 'mandates-side-panel', action: 'replace' });
            }
          },
          dataPointMouseEnter(event, chartContext, config) {
            const { dataPointIndex } = config;

            const otherRects = config.w.globals.dom.baseEl.querySelectorAll(`.apexcharts-treemap-rect:not([j='${dataPointIndex}'])`);
            otherRects.forEach((node) => node.classList.add('inactive-hovered'));
          },
          dataPointMouseLeave(event, chartContext, config) {
            const rects = config.w.globals.dom.baseEl.querySelectorAll('.apexcharts-treemap-rect');
            rects.forEach((node) => node.classList.remove('inactive-hovered'));
          },
        },
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      grid: {
        bottom: 50,
      },
      dataLabels: {
        style: {
          fontSize: '10px',
        },
        formatter: (val, opts) => {
          if (opts.value > 0) {
            const label = this.groupedByValue === 'nomenclature_code'
              ? val.split(' - ')[0]
              : val;
            return [label, euroFormatter(opts.value)];
          }
          return '';
        },
      },
      legend: {
        show: true,
        offsetY: 10,
        itemMargin: {
          vertical: 10,
        },
      },
      plotOptions: {
        treemap: {
          enableShades: false,
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (val) => euroFormatter(val),
        },
      },
      series: this.seriesValue,
      colors: this.colors,
    };

    if (this.printedValue) {
      return merge(opts, this.printedOptions);
    }
    return opts;
  }

  get printedOptions() {
    return {
      chart: {
        toolbar: {
          show: false,
        },
      },
    };
  }

  get colors() {
    const legalStates = this.seriesValue.map((serie) => serie.name);
    return getColorsFromLegalStates(legalStates);
  }
}
