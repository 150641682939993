import { Controller } from '@hotwired/stimulus';
import { smoothScrollTo } from '../utils';

export default class extends Controller {
  static values = {
    targetId: String,
    offset: {
      type: Number,
      default: 0,
    },
  };

  connect() {

  }

  scroll(event) {
    event.preventDefault();
    smoothScrollTo(this.targetIdValue, this.offsetValue);
  }
}
