import { Controller } from '@hotwired/stimulus';

// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  static targets = ['sentinel', 'stickable'];

  connect() {
    this.observer = new window.IntersectionObserver(
      this.intersectionHandler.bind(this),
      {
        root: null,
        rootMargin: '0px',
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      },
    );
    // give the observer some dom nodes to keep an eye on
    this.observer.observe(this.sentinelTarget);
  }

  intersectionHandler(entries) {
    // console.log(entries[0]);
    // entries is an array of observed dom nodes
    // we're only interested in the first one at [0]
    // because that's our .sentinal node.
    // Here observe whether or not that node is in the viewport

    // if (!entries[0].isIntersecting) {
    //   this.stickableTarget.classList.add('sticky-transition');
    // } else {
    //   this.stickableTarget.classList.remove('sticky-transition');
    // }
    const entry = entries[0];

    if (entry.isIntersecting && entry.intersectionRatio === 1) {
      this.stickableTarget.classList.remove('sticky-transition');
      this.stickableTarget.style.backgroundColor = null;
    } else {
      this.stickableTarget.classList.add('sticky-transition');
      this.stickableTarget.style.backgroundColor = `rgba(255,255,255,${1 - entry.intersectionRatio})`;
    }
  }
}
