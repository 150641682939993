import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  static values = {
    auto: Boolean,
  };

  connect() {
    if (this.hasAutoValue && this.autoValue === true) {
      Turbo.visit(window.location);
    }
  }

  reload(e) {
    e.preventDefault();
    Turbo.visit(window.location);
  }
}
