import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { getMeta } from '../../utils';
// Connects to data-controller="assistant--filter-panel"
export default class extends Controller {
  static targets = ['input', 'autocompleteResultsList'];

  static values = {
    includedInCodeId: Number,
    excludedFromCodeId: Number,
    url: String,
  };

  connect() {
  }

  create(e) {
    e.preventDefault();

    this.autocompleteResultsListTarget.classList.remove('show');

    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.csrfToken,
        Accept: 'text/vnd.turbo-stream.html',
      },
      body: this.creationParams,
    }).then((r) => r.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  }

  get creationParams() {
    const data = new FormData();
    data.append('nomenclature_item[label]', this.inputTarget.value);
    data.append('nomenclature_item[included_in_code_id]', this.includedInCodeIdValue);
    data.append('nomenclature_item[excluded_from_code_id]', this.excludedFromCodeIdValue);

    return data;
  }

  get csrfToken() {
    return getMeta('csrf-token');
  }
}
