/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [];

  static values = {
    modelName: String,
  };

  initialize() {
    this.storageName = `bulk_${this.modelNameValue}_selection`;
    this.selected = JSON.parse(sessionStorage.getItem(this.storageName)) || [];
  }

  connect() {
    const event = new CustomEvent('bulk-select:checkbox:checked', {
      detail: {
        checkbox: this.element,
      },
    });

    if (this.selected.includes(this.element.value)) {
      this.element.checked = true;
      document.dispatchEvent(event);
    } else if (this.element.checked) {
      this.element.checked = false;
    }
  }
}
