import { Controller } from '@hotwired/stimulus';
import tippy from 'tippy.js';

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = ['source'];

  connect() {
    this.tippy = tippy(this.sourceTarget, {
      content: 'Copié !',
      trigger: 'manual',
      onShow(instance) {
        setTimeout(() => {
          instance.hide();
        }, 3000);
      },
    });
  }

  async copy() {
    await navigator.clipboard.writeText(this.sourceTarget.value);
    this.tippy.show();
  }
}
