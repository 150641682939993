import { Controller } from '@hotwired/stimulus';
import { dateFromLocaleString } from '../../utils';
import TurboRequest from '../../turbo_request';

// Connects to data-controller="planner--roadmap-timeline"
export default class extends Controller {
  static targets = ['step', 'dropdown', 'endOnMarker'];

  static values = {
    marketId: String,
    marketEndOn: String,
    bulkUpdateUrl: String
  };

  connect() {
    if (this.hasMarketEndOnValue) {
      this.endOnDate = dateFromLocaleString(this.marketEndOnValue);
      this.positionEndOnDate();
    }
  }

  updateProcessSteps(event) {
    const dates = this.stepTargets.map((stepElement) => {
      return {
        id: stepElement.dataset["planner-RoadmapStepIdValue"],
        start_on: stepElement.dataset["planner-RoadmapStepStartOnValue"],
        end_on: stepElement.dataset["planner-RoadmapStepEndOnValue"]
      }
    })

    const body = {
      steps: dates
    }

    const request = new TurboRequest(this.bulkUpdateUrlValue, body, 'POST');
    request.call();
  }

  positionEndOnDate(e) {
    if (e) e.preventDefault();
    const newX = this.endOnOffsetLeft;
    this.endOnMarkerTarget.style.transform = `translateX(${newX}px)`;
    this.endOnMarkerTarget.dataset.x = newX;
  }

  get endOnOffsetLeft() {
    return document.querySelector(`[data-date="${this.marketEndOnValue}"]`).offsetLeft;
  }

  // dragAllSteps(event) {
  //   event.preventDefault();
  //   event.stopPropagation();

  //   this.stepTargets.forEach((step) => {
  //     step.dataset.multidrag = true;
  //     step.classList.add('roadmap-timeline-step--selected');
  //   });

  //   const dropdown = Dropdown.getInstance(this.dropdownTarget);
  //   dropdown.hide();

  //   document.addEventListener('click', this.stopDragAllHandler);
  // }

  // // Au click sur un élément qui n'est pas une étape, ni inclu dans une étape,
  // // arrêter la sélection
  // stopDragAll(event) {
  //   const { target } = event;

  //   const insideStep = this.stepTargets.some((step) => step === target || step.contains(target));

  //   if (!insideStep) {
  //     this.stepTargets.forEach((step) => {
  //       step.dataset.multidrap = false;
  //       step.classList.remove('roadmap-timeline-step--selected');
  //     });
  //   }

  //   document.removeEventListener('click', this.stopDragAllHandler);
  // }
}
