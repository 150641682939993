import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs/modular/sortable.core.esm.js';

// Connects to data-controller="sortable"
export default class extends Controller {
  static targets = ['item'];

  static values = {
    idAttribute: String,
  };

  connect() {
    this.sortable = Sortable.create(this.element, {
      draggable: '.sortable-item',
      handle: '.drag-handle',
      dataIdAttr: this.idAttributeValue,
      onEnd: () => {
        this.onDragEnd();
      },
    });
  }

  itemTargetConnected(item) {
    const input = item.querySelector('[data-position-input="true"]');
    if (input.value.length === 0 || input.value === '0') {
      this.itemTargets.forEach((itemInList, index) => {
        if (item === itemInList) {
          const posInput = itemInList.querySelector('[data-position-input="true"]');
          posInput.value = index;
        }
      });
    }
  }

  onDragEnd() {
    this.itemTargets.forEach((item, index) => {
      const input = item.querySelector('[data-position-input="true"]');
      input.value = index;
    });
  }
}
