import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import ApexCharts from 'apexcharts';
import {
  getCSSColor,
  euroFormatter,
  chartLocale,
} from '../../utils';

// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  static targets = ['chart'];

  static values = {
    series: Array,
    categories: Array,
    annotations: Number,
  };

  initialize() {
    this.color = getCSSColor('primary');
    this.color2 = getCSSColor('accent2');
    this.accentColor = getCSSColor('accent1');
    this.predictionsColor = getCSSColor('gray-600');
    console.log('this anotaion', this.annotationsValue);
  }

  connect() {
    // console.log("series", chartLocale)
    this.options = {
      chart: {
        ...chartLocale,
        height: 400,
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        type: 'category',
        categories: this.categoriesValue,
        labels: {
          formatter: (val) => new Date(val).toLocaleString('fr-FR', { dateStyle: 'short' }),
        },
      },
      colors: [this.color, this.color2, this.predictionsColor],
      fill: {
        colors: [this.color, this.color2, this.predictionsColor],
      },
      stroke: {
        width: 2,
        colors: [this.color, this.color2, this.predictionsColor],
        dashArray: [0, 5, 5],
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1, 2],
        formatter: (val) => (val === null ? '' : euroFormatter(val)),
        background: {
          enabled: true,
          foreColor: '#FFF',
          padding: 6,
          borderRadius: 0,
          borderWidth: 2,
          opacity: 0.9,
        },
      },
      series: this.seriesValue,
    };

    if (this.annotationsValue > 0) {
      this.options.annotations = {
        yaxis: [{
          y: this.annotationsValue,
          borderColor: this.accentColor,
          label: {
            borderColor: this.accentColor,
            style: {
              color: '#fff',
              background: this.accentColor,
            },
            text: 'Seuil de marché public',
          },
        }],
      };
    }

    const chart = new ApexCharts(this.chartTarget, this.options);
    chart.render();
  }
}
