import { Controller } from '@hotwired/stimulus';
import Cookies from 'js-cookie';

// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  connect() {
    this.element.classList.add('welcome--active');
  }

  close(event) {
    event.preventDefault();
    this.element.remove();

    document.querySelector("input[type=text]").focus();
    Cookies.set("_crtp_saw_welcome", true, {
      path: '/'
    })
  }
}
