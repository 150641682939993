import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['header'];

  connect() {
    this.pos1 = 0;
    this.pos2 = 0;
    this.pos3 = 0;
    this.pos4 = 0;

    this.attachMouseDownListener();
  }

  attachMouseDownListener() {
    this.headerTarget.addEventListener('mousedown', this.mouseDown.bind(this));
  }

  mouseDown(e) {
    e.preventDefault();
    // get the mouse cursor position at startup:
    this.pos3 = e.clientX;
    this.pos4 = e.clientY;
    document.onmouseup = this.closeDragElement.bind(this);
    // call a function whenever the cursor moves:
    document.onmousemove = this.elementDrag.bind(this);
  }

  closeDragElement(e) {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
    console.log('fo');
  }

  elementDrag(e) {
    e.preventDefault();
    // calculate the new cursor position:
    this.pos1 = this.pos3 - e.clientX;
    this.pos2 = this.pos4 - e.clientY;
    this.pos3 = e.clientX;
    this.pos4 = e.clientY;
    // set the element's new position:
    this.element.style.top = `${this.element.offsetTop - this.pos2}px`;
    this.element.style.left = `${this.element.offsetLeft - this.pos1}px`;
  }
}
