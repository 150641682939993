/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['row', 'body', 'head'];

  initialize() {
    this.shownKeyboardNotif = false;
    this.keyDownHandler = this.onKeyDown.bind(this);
  }

  connect() {
    this.element.addEventListener('focusin', (event) => {
      if (event.target !== this.element) return;
      this.currentActiveRow = null;
    });
    this.element.addEventListener('keydown', this.keyDownHandler);

    // pour le cas où le focus viendrait de la souris plutôt que des raccourcis clavier
    this.rowTargets.forEach((row) => {
      row.addEventListener('focusin', (event) => {
        this.currentActiveRow = event.target;
      });
    });
    // });
  }

  onKeyDown(event) {
    if (event.keyCode === 40) { // Down
      event.preventDefault();
      event.stopPropagation();
      if (!this.currentActiveRow) {
        [this.currentActiveRow] = this.rowTargets;
      } else if (this.currentActiveRow === this.rowTargets[this.rowTargets.length - 1]) {
        return;
      } else {
        this.currentActiveRow = this.currentActiveRow.nextElementSibling;
      }

      if (this.currentActiveRow) {
        this.scrollIntoViewIfNeeded(this.currentActiveRow);
        this.currentActiveRow.focus();
      }
    } else if (event.keyCode === 38) { // Up
      event.preventDefault();
      if (!this.currentActiveRow) {
        return;
      }
      if (this.currentActiveRow === this.rowTargets[0]) {
        return;
      }
      this.currentActiveRow = this.currentActiveRow.previousElementSibling;

      if (this.currentActiveRow) {
        this.scrollIntoViewIfNeeded(this.currentActiveRow);
        this.currentActiveRow.focus();
      }
    }
  }

  scrollIntoViewIfNeeded(target) {
    // Obtenir l'élément parent
    const parent = this.element;

    // Calculer la distance de l'élément enfant depuis le haut et le bas du parent
    const parentRect = parent.getBoundingClientRect();
    const childRect = target.getBoundingClientRect();
    const topOffset = childRect.top - parentRect.top - this.headTarget.clientHeight;
    const bottomOffset = childRect.bottom - parentRect.bottom;

    // Vérifier si l'élément enfant est complètement visible
    const isCompletelyVisible = topOffset >= 0 && bottomOffset <= 0;
    // Si l'élément enfant n'est pas complètement visible, faire défiler le parent
    if (!isCompletelyVisible) {
      // Vérifier si l'élément est partiellement visible en haut du parent
      const isPartiallyVisibleAtTop = topOffset < 0;

      if (isPartiallyVisibleAtTop) {
        parent.scrollTo({
          top: parent.scrollTop + topOffset,
          behavior: 'smooth',
        });
      } else {
        parent.scrollTo({
          top: parent.scrollTop + bottomOffset,
          behavior: 'smooth',
        });
      }
    }
  }
}
