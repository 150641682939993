const movementDirection = (base, event) => {
  let dir;
  if (event.screenX < base) {
    dir = 'left';
  } if (event.screenX > base) {
    dir = 'right';
  }

  return dir;
};

const areIntersecting = (rect1, rect2) => rect1.right > rect2.left && rect1.left < rect2.right;

const toggleCurrentDateMarker = (elementDomRect, activeOnLeft = true, deactivateOthers = true) => {
  let dateMarker;

  const classToAdd = activeOnLeft
    ? 'active--left'
    : 'active--right';

  [...document.querySelectorAll('.roadmap__date-marker')].forEach((dateMarkerElement) => {
    const elementRect = {
      left: parseFloat(dateMarkerElement.dataset.screenX),
      right: parseFloat(dateMarkerElement.dataset.screenX) + dateMarkerElement.offsetWidth,
    };
    if (areIntersecting(elementDomRect, elementRect)) {
      dateMarker = dateMarkerElement;
      dateMarkerElement.classList.add('active', classToAdd);
    } else if (deactivateOthers) {
      dateMarkerElement.classList.remove('active', 'active--right', 'active--left');
    }
  });

  return dateMarker;
};

const findDateMarker = (elementDomRect) => {
  const dateMarker = [...document.querySelectorAll('[data-screen-x]')].find((dateMarkerElement) => {
    const dateMarkerRect = {
      left: parseFloat(dateMarkerElement.dataset.screenX),
      right: parseFloat(dateMarkerElement.dataset.screenX) + dateMarkerElement.offsetWidth,
    };

    return areIntersecting(elementDomRect, dateMarkerRect);
  });

  return dateMarker;
};

const toggleStartAndEndDateMarkersSynchronously = (elementRect) => {
  let startDateMarker;
  let endDateMarker;

  const leftEdge = {
    left: elementRect.left,
    right: elementRect.left,
  };

  const rightEdge = {
    left: elementRect.right,
    right: elementRect.right,
  };

  // console.log('Left edge', leftEdge);

  [...document.querySelectorAll('[data-screen-x]')].forEach((dateMarkerElement) => {
    const dateMarkerElementRect = dateMarkerElement.getBoundingClientRect();

    if (areIntersecting(leftEdge, dateMarkerElementRect)) {
      startDateMarker = dateMarkerElement;
      dateMarkerElement.classList.add('active', 'active--left');
    } else if (areIntersecting(rightEdge, dateMarkerElementRect)) {
      endDateMarker = dateMarkerElement;
      dateMarkerElement.classList.add('active', 'active--right');
    } else {
      dateMarkerElement.classList.remove('active', 'active--right', 'active--left');
    }
  });

  // console.log('toggleStartAndEndDateMarkers', startDateMarker, endDateMarker);

  return [startDateMarker, endDateMarker];
};

const addDaysToDate = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const substractDaysToDate = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

const getAllSiblings = (element, parent) => [...getPreviousSiblings(element, parent), ...getNextSiblings(element, parent)];

const getPreviousSiblings = (element, parent) => {
  const children = [...parent.children].filter((s) => s.classList.contains(element.classList[0]));
  return children.splice(0, children.indexOf(element));
};

const getNextSiblings = (element, parent) => {
  const children = [...parent.children].filter((s) => s.classList.contains(element.classList[0]));
  return children.splice(children.indexOf(element) + 1, children.length);
};

export {
  areIntersecting,
  movementDirection,
  findDateMarker,
  toggleCurrentDateMarker,
  toggleStartAndEndDateMarkersSynchronously,
  addDaysToDate,
  substractDaysToDate,
  getAllSiblings,
  getPreviousSiblings,
  getNextSiblings,
};
