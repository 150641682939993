/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import { getMeta } from '../../utils';

// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  static targets = [
    'elementIdInput',
    'urlInput',
    'themisableTypeInput',
    'themisableIdInput',
    'humanThemisableResource',
    'themisableHintText',
    'currentResourceFieldWraper',
    'accountIdInput',
    'accountFieldWraper',
    'cartoapCodesFieldWraper',
    'cartoapCodeInput',
    'resourceTypeSelect',
  ];

  connect() {
    this.currentAccountId = getMeta('account');
    this.themisableResourceType = getMeta('themisable-resource-class');
    this.themisableResourceId = getMeta('themisable-resource-id');

    this.elems = [...document.querySelectorAll('[is="themisable-element"]')];
    this.setResourceTypeSelectOptions();
    this.highlightThemisElements();
    this.attachListenerOnThemisElements();
    this.setHumanThemisableContent();

    this.urlInputTarget.value = window.location;

    this.themisableResourceType = this.resourceTypeSelectTarget.value || 'all';
    this.toggleFieldsBasedOnResourceValue();
  }

  setResourceTypeSelectOptions() {
    if (this.themisableResourceType === 'MandateProvider') {
      this.resourceTypeSelectTarget.querySelector('option[value="cartoap_code"]').remove();
    }
  }

  attachListenerOnThemisElements() {
    this.elems.forEach((elem) => {
      elem.addEventListener('click', this.pickElement.bind(this));
    });
  }

  highlightThemisElements() {
    this.elems.forEach((elem) => {
      elem.classList.add('revealed', 'revealed--admin');
    });
  }

  pickElement(e) {
    e.preventDefault();
    const { themisId } = e.currentTarget.dataset;
    this.selectedElement = e.currentTarget;
    this.elementIdInputTarget.value = themisId;
  }

  setHumanThemisableContent() {
    const sentence = `Les pages à propos de ce ${this.themisableResourceType}`;

    this.humanThemisableResourceTarget.innerText = sentence;
  }

  changeThemisableResourceType(e) {
    const { value } = e.currentTarget;

    this.themisableResourceType = value;
  }

  changeCartoapCodeId(event) {
    if (event) event.preventDefault();
    const value = this.cartoapCodeId;
    this.themisableResourceId = value;
  }

  toggleFieldsBasedOnResourceValue() {
    if (this.themisableResourceType === 'all') {
      this.cartoapCodesFieldWraperTarget.style.display = 'none';
      this.currentResourceFieldWraperTarget.style.display = 'none';
      this.accountFieldWraperTarget.style.display = 'block';

      this.themisableResourceType = null;
      this.themisableResourceId = null;
    } else if (this.themisableResourceType === 'cartoap_code') {
      this.themisableResourceType = 'CartoapNomenclatureCode';

      this.currentResourceFieldWraperTarget.style.display = 'none';
      this.cartoapCodesFieldWraperTarget.style.display = 'block';
      this.accountFieldWraperTarget.style.display = 'block';

      this.changeCartoapCodeId();
    } else if (this.themisableResourceType === 'current_resource') {
      this.themisableResourceType = getMeta('themisable-resource-class');
      this.themisableResourceId = getMeta('themisable-resource-id');

      this.currentResourceFieldWraperTarget.style.display = 'block';
      this.cartoapCodesFieldWraperTarget.style.display = 'none';

      this.accountIdInputTarget.value = this.currentAccountId;
      this.accountFieldWraperTarget.style.display = 'none';
    }
  }

  set selectedElement(element) {
    this.elems.forEach((elem) => {
      elem.classList.remove('picked');
    });

    element.classList.add('picked');
    this._selectedElement = element;
  }

  get selectedElement() {
    return this._selectedElement;
  }

  set themisableResourceType(value) {
    this.themisableTypeInputTarget.value = value;
    this._themisableResourceType = value;
  }

  get themisableResourceType() {
    return this._themisableResourceType;
  }

  set themisableResourceId(value) {
    this.themisableIdInputTarget.value = value;
    this._themisableResourceId = value;
  }

  get themisableResourceId() {
    return this._themisableResourceId;
  }

  get cartoapCodeId() {
    return this.cartoapCodeInputTarget.value;
  }
}
