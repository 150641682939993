/* eslint-disable no-underscore-dangle */
import { createPopper } from '@popperjs/core';
import { getMeta } from '../../utils';

export default class Themis {
  static start = () => {
    document.documentElement.addEventListener('turbo:load', () => {
      window.ThemisInstance = new Themis();
    });
  };

  static createPanel() {
    const frame = document.createElement('turbo-frame');
    frame.setAttribute('id', 'themis-panel');
    frame.dataset.controller = 'themis--panel';
    frame.classList.add('themis-panel');
    document.body.appendChild(frame);

    const indexFrame = document.createElement('turbo-frame');
    indexFrame.setAttribute('id', 'themis-panel-index');

    const showFrame = document.createElement('turbo-frame');
    showFrame.setAttribute('id', 'themis-panel-show');

    frame.appendChild(indexFrame);
    frame.appendChild(showFrame);

    return frame;
  }

  static get themisableNodes() {
    return [...document.querySelectorAll('[is="themisable-element"]')];
  }

  constructor() {
    this.themisableResourceClass = getMeta('themisable-resource-class');
    this.themisableResourceId = getMeta('themisable-resource-id');

    if (Themis.themisableNodes.length) {
      this.themisPanelElement = Themis.createPanel();
      this.loadThemis();
    }
  }

  showPanel(themisableElement) {
    this.themisableElement = themisableElement;

    Themis.themisableNodes
      .filter((element) => element.classList.contains('themis-revealed'))
      .forEach((element) => element.classList.remove('themis-revealed'));

    this.popperInstance = this.createPopperInstance();
  }

  createPopperInstance() {
    return createPopper(
      this.themisableElement.notificationElement,
      this.themisPanelElement,
      {
        placement: 'auto-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [10, 20],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              altAxis: true, // false by default
              padding: 8,
            },
          },
        ],
      },
    );
  }

  unRevealThemisableElement() {
    this.themisableElement.unreveal();
  }

  async loadThemis() {
    this.themisableNodes = Themis.themisableNodes;
    this.themisableIds = this.themisableNodes.map((node) => node.dataset.themisId);

    this.content = await this.fetchContent();
    if (this.content.length) {
      const themisableElements = this.themisableNodes;

      themisableElements.forEach((element) => {
        const localElement = element;
        const { themisId } = element.dataset;
        const notificationIds = this.content.filter(
          (notif) => notif.element_ids.includes(themisId),
        ).map((notif) => notif.id);
        localElement.dataset.themisNotificationsIds = notificationIds.join(',');
      });
    }
  }

  updatePlacement() {
    console.log('notif', this.themisableElement.notificationElement);
    this.popperInstance.reference = this.themisableElement.notificationElement;
    this.popperInstance.update();
  }

  async fetchContent() {
    const params = new URLSearchParams({
      themisable_type: this.themisableResourceClass,
      themisable_id: this.themisableResourceId,
      element_ids: this.themisableIds,
    });

    const response = await fetch(`/themis/notifications?${params.toString()}`);
    return await response.json();
  }
}
