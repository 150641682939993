/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';
import Timeline from '../../timeline';

const dateRangeOverlaps = (aStart, aEnd, bStart, bEnd) => {
  if (aStart <= bStart && bStart <= aEnd) return true; // b starts in a
  if (aStart <= bEnd && bEnd <= aEnd) return true; // b ends in a
  if (bStart < aStart && aEnd < bEnd) return true; // a in b
  return false;
};

export default class extends Controller {
  static targets = ['chart'];

  static values = {
    publicMarkets: Array,
  };

  connect() {
    console.log('chronologu');
    this.lines = [];
    this.buildInitialLines();
    this.timeline = new Timeline(this.element, this.lines);
    this.timeline.displayLines();

    this.scrollToCurrentYear();
    this.hoverListener();
  }

  hoverListener() {
    [...this.element.querySelectorAll('.timeline__event')].forEach((event) => {
      event.addEventListener('mouseenter', () => {
        console.log('hovering');
        this.element.classList.add('hovering');
      });
      event.addEventListener('mouseleave', () => this.element.classList.remove('hovering'));
    });
  }

  scrollToCurrentYear() {
    const currentYear = this.element.querySelector('[data-current-year="true"]');
    this.element.scrollBy({ left: currentYear.offsetLeft });
  }

  buildInitialLines() {
    this.publicMarketsValue.forEach((pm) => {
      let assigned = false;
      pm.y = [
        new Date(pm.start_on).getTime(),
        new Date(pm.end_on).getTime(),
      ];

      for (let i = 0; i < this.lines.length; i++) {
        const line = this.lines[i];
        let canAssign = true;

        // Vérifier si le contrat chevauche avec un contrat existant sur cette ligne
        for (let j = 0; j < line.length; j++) {
          const existingContract = line[j];
          if (dateRangeOverlaps(
            pm.start_on,
            pm.end_on,
            existingContract.start_on,
            existingContract.end_on,
          )) {
            canAssign = false;
            break;
          }
        }

        // Si aucune collision, assigner le contrat à cette ligne
        if (canAssign) {
          pm.x = i;
          line.push(pm);
          assigned = true;
          break;
        }
      }

      // Si le contrat n'a pas pu être assigné à une ligne existante, créer une nouvelle ligne
      if (!assigned) {
        pm.x = this.lines.length + 1;
        this.lines.push([pm]);
      }
    });
  }
}
