import { Controller } from '@hotwired/stimulus';

// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  dismiss(event) {
    event.preventDefault();

    this.element.remove();
  }
}
