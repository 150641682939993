/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus';
import tippy from 'tippy.js';
import { draggedElementStore } from '../../../utils';

const dataTransferType = 'jsonObj';
const draggedClass = 'mix-screen-code__connected-item--dragged';

export default class extends Controller {
  static values = {
    itemId: Number,
    tooltipSrcUrl: String,
  };

  initialize() {
    this.dragStartHandler = this.onDragstart.bind(this);
    this.dragEndHandler = this.onDragend.bind(this);
  }

  connect() {
    this.element.addEventListener('dragstart', this.dragStartHandler);
    this.element.addEventListener('dragend', this.dragEndHandler);

    const src = this.tooltipSrcUrlValue;
    tippy(this.element, {
      content: 'Chargement...',
      allowHTML: true,
      theme: 'light',
      onShow(instance) {
        fetch(src)
          .then((response) => response.text())
          .then((html) => instance.setContent(html));
      },
    });
  }

  onDragstart(event) {
    draggedElementStore.draggedElement = this.element;

    this.element.classList.add(draggedClass);
    this.createDataTransferImg();

    const data = {
      operationType: 'createConnection',
      data: {
        itemId: this.itemIdValue,
      },
    };

    event.dataTransfer.setDragImage(this.dataTransferImg, 0, 0);
    event.dataTransfer.setData(dataTransferType, JSON.stringify(data));

    // // Will be listened to in assistant-mix-code-controller
    // // to update the code-controller state accordingly
    // const customDragStartedEvent = new CustomEvent(
    //   'nomenclature-item:dragstarted',
    //   {
    //     detail: {
    //       itemId: data.itemId,
    //     },
    //   },
    // );
    // document.documentElement.dispatchEvent(customDragStartedEvent);
  }

  onDragend() {
    draggedElementStore.draggedElement = null;
    this.element.classList.remove(draggedClass);

    // // Will be listened to in assistant-mix-code-controller
    // // to update the code-controller state accordingly
    // const customDragEndedEvent = new CustomEvent(
    //   'nomenclature-item:dragended',
    //   {
    //     detail: {
    //       itemId: this.itemIdValue,
    //     },
    //   },
    // );
    // document.documentElement.dispatchEvent(customDragEndedEvent);
  }

  createDataTransferImg() {
    const classes = [...this.element.classList, ...['drag-image']].filter((className) => className != draggedClass);
    const previouslyExistingDragImage = !!document.getElementById('data-transfer-img');

    let element;
    if (previouslyExistingDragImage) {
      element = document.getElementById('data-transfer-img');
      element.classList.remove(...element.classList); // Reset classList
    } else {
      element = document.createElement('div');
      element.id = 'data-transfer-img';
      element.style.position = 'fixed';
      element.style.left = '-2000px';
      document.body.appendChild(element);
    }

    element.classList.add(...classes);
    element.innerText = this.element.innerText;
    element.style.width = this.element.width;
    element.style.maxWidth = this.element.width;
    element.style.height = this.element.height;

    this.dataTransferImg = element;
  }

  updateDataTransferImg() {
    this.dataTransferImg.innerText = `${this.selectedRowIds.length} mandat(s)`;
  }
}
