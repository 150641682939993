import { Controller } from '@hotwired/stimulus';
// Connects to data-controller="assistant--filter-panel"

const buildFieldName = () => `nomenclature_code[family_related_codes_attributes][${Date.now()}]`;

export default class extends Controller {
  static targets = ['template', 'select', 'familyRelatedCodeBtn', 'familyRelatedCodesList'];

  static values = {
    selectedFamilyIds: {
      type: Array,
      default: [],
    },
  };

  connect() {
    this.selectedFamilyIds = this.selectedFamilyIdsValue;

    this.selectedFamilyIds.forEach((id) => {
      this.disableOptionForFamilyId(id);
    });
  }

  disableOptionForFamilyId(id) {
    const selectedOption = this.selectTarget.querySelector(`option[value="${id}"]`);
    selectedOption.disabled = true;
  }

  destroy(event) {
    const target = event.currentTarget;
    const { familyId } = target.dataset;

    console.log('family Id', event, familyId);

    const index = parseInt(this.selectedFamilyIds.indexOf(familyId), 10);
    this.selectedFamilyIds.splice(index, 1);

    const selectedOption = this.selectTarget.querySelector(`option[value="${familyId}"]`);
    selectedOption.disabled = false;

    target.style.display = 'none';
  }

  add(event) {
    const fieldName = buildFieldName();
    const select = event.target;
    const selectedOption = select.querySelector('option:checked');
    const id = selectedOption.getAttribute('value');
    const label = selectedOption.innerText;

    const clone = this.templateTarget.cloneNode(true);
    clone.innerHTML = clone.innerHTML
      .replace('__LABEL__', label)
      .replaceAll('__NOMENCLATURE_FAMILY_ID__', id)
      .replaceAll('__FIELD_NAME__', fieldName);

    const fragment = document.importNode(clone.content, true);

    this.familyRelatedCodesListTarget.append(fragment);

    this.selectedFamilyIds.push(id);

    selectedOption.disabled = true;
    select.value = '';
  }
}
