import consumer from './consumer';
import Notification from '../notification';

const createNotificationSubscription = (userId) => {
  var arr_of_identifiers = consumer.subscriptions.subscriptions.map(s => {
    return s.identifier
  });
  console.log("arr of ids", arr_of_identifiers)

  return consumer.subscriptions.create(
    {
      channel: 'NotificationsChannel',
      user_id: userId,
    },
    {
      // Called once when the subscription is created.
      initialized() {
        // alert("channel initialisation")
      },

      // Called when the subscription is ready for use on the server.
      connected() {
        // alert("channel connection")
      },

      // Called when the WebSocket connection is closed.
      disconnected() {
        // alert("channel disconnection")
      },

      received(data) {
        const type = data.type || "notice"
        const notification = new Notification(type, { body: data.body });
        notification.show();
      },
    }
  );
};

export default createNotificationSubscription;
