import { Controller } from '@hotwired/stimulus';
import { loaderTemplate } from '../utils';

export default class extends Controller {
  connect() {
    // // alert('ok');
    // // this.element.style.opacity = 1;
    // this.element.style.height = '1px';

    const img = new Image();
    img.onload = () => {
      this.element.style.display = 'block';
      this.hideLoader();
    };
    img.src = this.element.src;

    this.showLoader();
  }

  hideLoader() {
    this.wraper.remove();
  }

  showLoader() {
    this.wraper = document.createElement('div');
    this.wraper.classList.add('d-flex', 'justify-content-center');
    this.wraper.innerHTML = loaderTemplate;
    this.element.after(this.wraper);
  }
}
