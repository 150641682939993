import { Controller } from '@hotwired/stimulus';

// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  static targets = ['ruleTypeCharCountInput', 'customPatternInput'];

  static values = {
    initialRuleType: String,
    initialPatternType: String,
    initialPublicMarket: Boolean,
    initialCustomPattern: String
  };

  connect() {
    console.log("Regex builder connection", this.initialRegexValue)

    this.parentForm = this.element.parentElement.closest('form');

    this.ruleType = this.initialRuleTypeValue;
    this.patternType = this.initialPatternTypeValue;
    this.publicMarket = this.initialPublicMarketValue;
  }

  remove(e) {
    this.element.style.display = "none";
  }
  
  toggleOpacity(selectorClass, value) {
    const allParents = [...this.element.querySelectorAll(`${selectorClass}`)];

    allParents.forEach(node => {
      if (node.dataset.value != value.toString()) {
        node.style.opacity = "0.5";
        node.querySelector('input[type="radio"]').checked = false;
      } else {
        node.style.opacity = "1"
      }
    })
  }

  pickRuleType(e) {
    const parent = e.currentTarget;
    const button = parent.querySelector('input[type="radio"]');

    const value = button.value;
    this.ruleType = value;

    button.checked = true;

    if (this.ruleType == "characters_after_n") {
      this.ruleTypeCharCountInputTarget.required = true;
      this.parentForm.reportValidity(); // Show the user he needs to fill this input
    } else {
      this.ruleTypeCharCountInputTarget.required = false;
    } 
  }

  pickPatternType(e) {
    const parent = e.currentTarget;
    const button = parent.querySelector('input[type="radio"]');

    const value = button.value;
    this.patternType = value;

    button.checked = true;

    if (this.patternType == "custom") {
      this.customPatternInputTarget.required = true;
      this.parentForm.reportValidity(); // Show the user he needs to fill this input
    } else {
      this.customPatternInputTarget.required = false;
    }
  }

  pickPublicMarket(e) {
    const parent = e.currentTarget;
    const button = parent.querySelector('input[type="radio"]');

    const value = button.value;
    this.publicMarket = value == "true";

    button.checked = true;
  }

  set ruleType(value) {
    this._ruleType = value

    this.toggleOpacity(".js-rule-type", value);
  }

  get ruleType() {
    return this._ruleType;
  }

  set patternType(value) {
    this._patternType = value

    this.toggleOpacity(".js-pattern-type", value);
  }

  get patternType() {
    return this._patternType;
  }

  set publicMarket(value) {
    this._publicMarket = value;

    this.toggleOpacity(".js-public-market", value);
  }

  get publicMarket() {
    this._publicMarket;
  }

  get patternValue() {
    let value;
    switch (this.patternType) {
      case "any_letter":
        value = "\\D";
        break;
      case "any_digit":
        value = "\\d";
        break;
      case "custom":
        value = this.customPattern;
        break;  
      default:
        break;
    }

    return value;
  }
}
